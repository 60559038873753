import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';

class Comment extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query(this.appState);

    const {
      match,
    } = this.props;
    const { params } = match;
    const { shortcode } = params;

    this.state = {
      shortcode,
    };
  }

  componentDidMount() {
    const { shortcode } = this.state;
    if (this.settings.getAllowComments()) {
      if (shortcode) {
        // RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE
        // SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
        // LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT:
        // https://disqus.com/admin/universalcode/#configuration-variables
        const disqus_config = function () { // eslint-disable-line camelcase, no-unused-vars, func-names, max-len
          // Replace PAGE_URL with your page's canonical URL variable
          this.page.url = this.settings.getBaseUrl();
          // Replace PAGE_IDENTIFIER with your page's unique identifier variable
          this.page.identifier = shortcode;
        };
        (function () { // eslint-disable-line func-names
          const d = document;
          const s = d.createElement('script');
          s.src = 'https://https-lowendoffer-win.disqus.com/embed.js';
          s.setAttribute('data-timestamp', +new Date());
          (d.head || d.body).appendChild(s);
        }());
      }
    }
  }

  render() {
    return (
      <>
        <div id="disqus_thread" />
      </>
    );
  }
}

Comment.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
  match: PropTypes.element.isRequired,
};

Comment.defaultProps = {
  parent: null,
};

export default withRouter(Comment);
