import React, { Component } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import CookieConsent, { Cookies } from 'react-cookie-consent'; // eslint-disable-line no-unused-vars

import 'primeflex/primeflex.css';

// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/themes/mdc-light-indigo/theme.css';
// import 'primereact/resources/themes/mdc-dark-indigo/theme.css';
// import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './assets/primereact.css';
import './assets/datatable.css';
import './assets/App.css';

import Settings from './settings/settings';
import AppState from './appstate/appstate';
import Locale from './locale/locale';
import Console from './console/console';
import Sitemap from './sitemap/sitemap';
import Version from './version/version';
import Query from './query/query';
import Network from './network/network';

import HomePage from './routes/HomePage';
import LoginForm from './routes/LoginForm';
import LogOutPage from './routes/LogOutPage';
import DashboardPage from './routes/DashboardPage';
import RegistrationForm from './routes/RegistrationForm';
import ForgotPasswordForm from './routes/ForgotPasswordForm';
import CompanyPage from './routes/CompanyPage';
import OffersFromCompanyPage from './routes/OffersFromCompanyPage';
import MapPage from './routes/MapPage';
import Page from './routes/Page';
import OfferDetailPage from './routes/OfferDetailPage';

import AppHeader from './template/default/layout/appheader';
import AppBody from './template/default/layout/appbody';
import AppFooter from './template/default/layout/appfooter';
import AppDebug from './template/default/debug/appdebug';

class App extends Component {
  constructor(props) {
    super(props);

    if (window.location.protocol !== 'https:') {
      window.location.protocol = 'https:';
    }

    this.appState = new AppState();
    this.appState.setUserIp();

    this.settings = new Settings();
    this.locale = new Locale();
    this.console = new Console();
    this.console = new Console();
    this.network = new Network();
    this.query = new Query();

    this.Login = this.Login.bind(this);
    this.Home = this.Home.bind(this);
    this.LogOutPage = this.LogOutPage.bind(this);
    this.Dashboard = this.Dashboard.bind(this);
    this.Register = this.Register.bind(this);
    this.ForgotPassword = this.ForgotPassword.bind(this);
    this.CompanyPage = this.CompanyPage.bind(this);
    this.MapPage = this.MapPage.bind(this);
    this.Page = this.Page.bind(this);
    this.OfferDetailPage = this.OfferDetailPage.bind(this);
    this.OffersFromCompany = this.OffersFromCompany.bind(this);
    this.Sitemap = this.Sitemap.bind(this);
    this.VersionPage = this.VersionPage.bind(this);

    // theme
    /**/
    const styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    styleLink.href = 'https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css';
    document.head.appendChild(styleLink);
    /**/
    // end theme
  }

  componentDidMount() {
  }

  Home() {
    return (
      <div>
        <HomePage parent={this} />
      </div>
    );
  }

  Login() {
    return (
      <div>
        <h2>Login</h2>
        <LoginForm parent={this} />
      </div>
    );
  }

  LogOutPage() {
    return (
      <div>
        <LogOutPage parent={this} />
      </div>
    );
  }

  ForgotPassword() {
    return (
      <div>
        <ForgotPasswordForm parent={this} />
      </div>
    );
  }

  Register() {
    return (
      <div>
        <RegistrationForm parent={this} />
      </div>
    );
  }

  CompanyPage() {
    return (
      <div>
        <CompanyPage parent={this} />
      </div>
    );
  }

  OffersFromCompany() {
    return (
      <div>
        <OffersFromCompanyPage parent={this} />
      </div>
    );
  }

  MapPage() {
    return (
      <div>
        <MapPage parent={this} />
      </div>
    );
  }

  Dashboard() {
    return (
      <div>
        <DashboardPage parent={this} />
      </div>
    );
  }

  Page() {
    return (
      <div>
        <Page parent={this} />
      </div>
    );
  }

  OfferDetailPage() {
    return (
      <div>
        <OfferDetailPage parent={this} />
      </div>
    );
  }

  Sitemap() {
    return (
      <>
        <Sitemap parent={this} />
      </>
    );
  }

  VersionPage() {
    return (
      <>
        <Version parent={this} />
      </>
    );
  }

  render() {
    return (
      <div className="App">
        <AppHeader parent={this} />
        <div className="appBody">
          <Router>
            <AppBody parent={this} />
          </Router>
        </div>
        <AppFooter parent={this} />
        { (this.appState.isLoggedin) ? <AppDebug parent={this} /> : <></> }
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    );
  }
}

export default App;
