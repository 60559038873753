import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Query from '../../../query/query';
import Settings from '../../../settings/settings';
import OfferTemplateFunctions from '../offer/offertemplatefunctions';

class AppAdminManageOffers extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query(this.appState);

    this.otf = new OfferTemplateFunctions();

    // datatable templates
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);

    // this.handleApproveOffer = this.handleApproveOffer.bind(this);
    this.handleActivateOffer = this.handleActivateOffer.bind(this);
    this.handleInActivateOffer = this.handleInActivateOffer.bind(this);

    this.handleDeleteOffer = this.handleDeleteOffer.bind(this);

    this.loadOffers = this.loadOffers.bind(this);

    this.showToast = this.showToast.bind(this);

    this.state = {
      offerRows: [],
    };
  }

  componentDidMount() {
    this.loadOffers();
  }

  handleInActivateOffer(event, offerId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getInActivateOfferEndPoint(offerId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.data.updateOffer) {
                if (json.data.updateOffer.active === false) {
                  this.showToast(`inActivate offer: ${offerId}`);
                  this.loadOffers();
                  this.setState({
                  });
                } else {
                  // alert('not un approved');
                  // this.loadOffers();
                  this.showToast(`not inActivate offer: ${offerId}`);
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleActivateOffer(event, offerId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getActivateOfferEndPoint(offerId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // console.log(`${JSON.stringify(json.data)}`);
              if (json.data.updateOffer) {
                if (json.data.updateOffer.active !== false) {
                  this.showToast(`Activate offer: ${offerId}`);
                  this.loadOffers();
                  this.setState({
                  });
                } else {
                  this.showToast(`Not Activate offer: ${offerId}`);
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleDeleteOffer(event, offerId) { // eslint-disable-line
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getDeleteOfferEndPoint(offerId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // console.log(`${json.result}`);
              // console.log(`${}`);
              if (json.deleteOffer) {
                if (json.deleteOffer.id) {
                  this.showToast(`deleted offer: ${offerId}`);
                  // console.log(`offer deleted ${json.deleteOffer.id}`);
                }
              }
              this.setState({
              });
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  showToast(detail = 'Message Content', summary: 'Success Message', severity = 'messafe', life = 10000) {
    if (this.toast) {
      this.toast.show({
        severity,
        summary,
        detail,
        life,
      });
    }
  }

  loadOffers() {
    // const body = JSON.stringify({});

    const {
      endPoint,
      body,
    } = this.query.getOfferEndPoint();

    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // this.appState.setOfferJson(json.data.Offers);
              if (json.data) {
                if (json.data.offers) {
                  if (json.data.offers.length > 0) {
                    const offerRows = json.data.offers;
                    offerRows.forEach((offerRow) => {
                      offerRow.actions = ( // eslint-disable-line no-param-reassign
                        <>
                          {(offerRow.active !== true)
                            ? (
                              <>
                                <Button
                                  className="pi pi-check"
                                  onClick={(event) => {
                                    this.handleActivateOffer(event, offerRow.id);
                                  }}
                                />
                                <br />
                                <br />
                              </>
                            )
                            : (
                              <>
                                <Button
                                  className="pi pi-times"
                                  onClick={(event) => {
                                    this.handleInActivateOffer(event, offerRow.id);
                                  }}
                                />
                                <br />
                                <br />
                              </>
                            )}

                          <br />
                          <br />
                          <Button
                            className="pi pi-trash"
                            onClick={(event) => {
                              this.handleDeleteOffer(event, offerRow.id);
                            }}
                          />
                          <br />
                          <br />
                        </>
                      ); // eslint-disable-line no-param-reassign
                    });

                    this.setState({
                      // offerColumns,
                      offerRows,
                    });
                  }
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        <strong>{`${rowData.name}`}</strong>
        <br />
        <strong>{(rowData.active === true) ? 'active' : 'not active' }</strong>
        <br />
      </>
    );
  }

  render() {
    const {
      offerRows,
    } = this.state;

    return (
      <>
        <>
          { (Array.isArray(offerRows))
            ? (
              <div>
                <DataTable
                  value={offerRows}
                  paginator
                  className="p-datatable-responsive"
                  rows={this.settings.getDefaultRowsPerPageOptions()}
                  rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
                >
                  <Column
                    key="name"
                    field="name"
                    header="Name"
                    body={this.column1BodyTemplate}
                    sortable
                    filter
                    reorderable
                  />
                  <Column
                    key="actions"
                    field="actions"
                    header="Actions"
                    style={{ width: '10vw' }}
                  />
                </DataTable>
              </div>
            )
            : <></> }
        </>
        <>
          <Toast
            position="top-right"
            ref={(el) => {
              this.toast = el;
            }}
          />
        </>
      </>
    );
  }
}

AppAdminManageOffers.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppAdminManageOffers.defaultProps = {
  parent: null,
};

export default AppAdminManageOffers;
