import React, { Component, Lazy, Suspense } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import 'primeicons/primeicons.css';
import { InputSwitch } from 'primereact/inputswitch'; // eslint-disable-line no-unused-vars
import Settings from '../settings/settings';
import Query from '../query/query';

// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';

const LightTheme = React.lazy( // eslint-disable-line no-unused-vars
  () => { import('primereact/resources/themes/bootstrap4-light-blue/theme.css'); },
);
const DarkTheme = React.lazy( // eslint-disable-line no-unused-vars
  () => { import('primereact/resources/themes/bootstrap4-dark-blue/theme.css'); },
);

class Theme extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query(this.appState);

    this.setValue = this.setValue.bind(this);

    this.state = {
      dark: false, // eslint-disable-line react/no-unused-state
    };
  }

  componentDidMount() {
  }

  setValue(value) {
    this.setState({
      dark: value,
    });
  }

  render() {
    const {
      dark,
    } = this.state;
    return (
      <>
        {/* {(dark) ? <i className="pi pi-moon" /> : <i className="pi pi-sun" /> } */}
        {/* <InputSwitch checked={dark} onChange={(e) => this.setValue(e.value)} /> */}
        <Suspense fallback={<>Loading...</>}>
          {(dark) && <LightTheme />}
          {(dark) && <DarkTheme />}
        </Suspense>
      </>
    );
  }
}

Theme.propTypes = {
  parent: PropTypes.object,
};

Theme.defaultProps = {
  parent: null,
};

export default Theme;
