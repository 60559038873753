import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Query from '../query/query';
import Settings from '../settings/settings';

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;

    this.query = new Query();
    this.settings = new Settings(); // eslint-disable-line 

    this.state = {
      username: '',
      email: '',
      password: '',
      password2: '',
      website: '',
      activationLinkSent: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRegistrationFormSubmit = this.handleRegistrationFormSubmit.bind(this);
    this.resendActivationLink = this.resendActivationLink.bind(this);
  }

  componentDidMount() { // eslint-disable-line 
  }

  handleChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  handleRegistrationFormSubmit(e) {
    e.preventDefault();

    const {
      // email,
      // password,
      isLoggedin,
      // serverRegisterEndPoint,
    } = this.appState;

    if (isLoggedin) {
      return;
    }

    const {
      email,
      password,
      password2,
      username,
      website,
    } = this.state;

    if (password !== password2) {
      const message = 'Passwords do not match';
      document.getElementById('registerResult').innerHTML = `${message}`;
      return;
    }

    const body = JSON.stringify({
      username,
      email,
      password,
      website,
    });

    const { parent } = this;
    const { appState } = parent;
    appState.username = username;
    appState.email = email;
    appState.password = password;
    appState.website = website;

    const serverRegisterEndPoint = this.query.getServerRegisterEndPoint();
    // this.appState.logify('serverRegisterEndPoint', serverRegisterEndPoint);
    console.log(`serverRegisterEndPoint: ${serverRegisterEndPoint}`);
    fetch(serverRegisterEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.isRegistered) {
                this.setState({ activationLinkSent: true });
                this.appState.token = json.token;
                document.getElementById('registerResult').innerHTML = `${json.message}`;
              } else {
                this.setState({ activationLinkSent: false });
                document.getElementById('registerResult').innerHTML = `Error: ${json.message}`;
              }

              /*
              if (json.isLoggedin) {
                appState.token = json.token;
                appState.isLoggedin = json.isLoggedin;
                this.parent.setState({}); // re-render
              } else {
                this.appState.isLoggedin = json.isLoggedin;
                document.getElementById('registerResult').innerHTML = `${json.message}`;
                this.parent.setState({}); // re-render
              }
              */
            },
          );
        }
      })
      .catch(
        (err) => { console.error(`loginEndPoint Fetch Error: ${err}`); },
      );
  }

  resendActivationLink(e) {
    e.preventDefault();

    const {
      // isLoggedin,
      email,
    } = this.appState;

    // if (isLoggedin) {
    //  return;
    // }

    const
      serverResendActivationLinkEndpointURL = this.query.getServerResendActivationLinkEndpointURL(
        email,
      ); // eslint-disable-line max-len
    // this.appState.logify(
    //   'serverResendActivationLinkEndpointURL',
    //   serverResendActivationLinkEndpointURL
    // );
    console.log(`serverResendActivationLinkEndpointURL: ${serverResendActivationLinkEndpointURL}`);
    fetch(serverResendActivationLinkEndpointURL,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.activationLinkSent) {
                // this.setState({ activationLinkSent: true });
                document.getElementById('registerResult').innerHTML = `${json.message}`;
              } else {
                // this.setState({ activationLinkSent: false });
                document.getElementById('registerResult').innerHTML = `${json.message}`;
              }
            },
          );
        }
      })
      .catch(
        (err) => { console.error(`resendActivationLink Fetch Error: ${err}`); },
      );
  }

  render() {
    const {
      isLoggedin,
      // username,
      // email,
      // password,
      // password2,
      // website,
      userIp,
    } = this.appState;

    const {
      activationLinkSent,
      username,
      email,
      password,
      password2,
      website,
    } = this.state;

    if (isLoggedin === true) {
      return (
        <div>
          You are already logged in.
          <br />
          {/* `Token: ${this.appState.token}` */}
          <br />
          <Link to={`${this.settings.getBaseUrl()}logout`}>Logout</Link>
          <div id="registerResult" />
        </div>
      );
    }

    if (activationLinkSent) {
      return (
        <div>
          {`Activation link sent to ${email}`}
          <br />
          <br />
          <Button variant="contained" onClick={this.resendActivationLink} type="submit">Resend Activation Link</Button>
          <br />
          <br />
          <Link to={`${this.settings.getBaseUrl()}login`}>Login</Link>
          <br />
          <div id="registerResult" />
          <br />
        </div>
      );
    }

    if (!this.settings.getAllowRegistration()) {
      return (
        <div className="appRegistrationForm">
          <h1>Registration Disabled</h1>
          <br />
        </div>
      );
    }

    return (
      <div className="appRegistrationForm">
        <h1>Registration Form</h1>
        <br />
        <div className="FormCenter">
          <form onSubmit={this.handleRegistrationFormSubmit} className="FormFields">
            <div className="FormField">
              <TextField type="text" id="username" className="FormField__Input" placeholder="Enter your username" name="username" value={username} onChange={this.handleChange} label="Username" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="email" id="email" className="FormField__Input" placeholder="Enter your email" name="email" value={email} onChange={this.handleChange} label="Email Address" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="password" id="password" className="FormField__Input" placeholder="Enter your password" name="password" value={password} onChange={this.handleChange} label="Password" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="password" id="password2" className="FormField__Input" placeholder="Re-Enter your password" name="password2" value={password2} onChange={this.handleChange} label="Re-Type Password" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="text" id="website" className="FormField__Input" placeholder="website" name="website" value={website} onChange={this.handleChange} label="Website" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <Button variant="contained" type="submit">Register</Button>
              <br />
            </div>
            <input type="hidden" value={userIp} />
          </form>
          <br />
          <div id="registerResult" />
        </div>
        <br />
        <div>Already a member?</div>
        <Link to={`${this.settings.getBaseUrl()}login`}>Login</Link>
        <br />
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

RegistrationForm.defaultProps = {
  parent: null,
};

export default RegistrationForm;
