import React from 'react';
import AppState from '../../../appstate/appstate'; // eslint-disable-line no-unused-vars
import Query from '../../../query/query';

const moment = require('moment');

function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

class OfferTemplateFunctions {
  constructor() {
    this.appState = new AppState();
    this.query = new Query(this.appState);
  }

  formatFieldName(fieldName) { // eslint-disable-line class-methods-use-this
    let name = '';
    if (fieldName !== null && fieldName !== undefined) {
      name = titleCase(fieldName.replaceAll('_', ' '));
    }
    return name;
  }

  formatCompanyName(companyName, companyId, baseUrl) { // eslint-disable-line class-methods-use-this
    let returnValue;
    returnValue = (
      <>
        {companyName}
      </>
    );

    if (companyName && companyId) {
      const link = `${baseUrl}p/${companyId}`;
      returnValue = (
        <>
          <a href={link}>
            {companyName}
          </a>
        </>
      );
    }
    return returnValue;
  }

  formatCpuSpeed(cpuSpeed) { // eslint-disable-line class-methods-use-this
    let returnValue = '';
    if (cpuSpeed) {
      returnValue = `@${cpuSpeed}MHz`;
    }
    return returnValue;
  }

  formatBoolean(booleanValue) { // eslint-disable-line class-methods-use-this
    const returnValue = (booleanValue) ? 'Yes' : 'No';
    return returnValue;
  }

  formatRam(ram) { // eslint-disable-line class-methods-use-this
    return `${ram} GB`;
  }

  formatRamType(ramType) { // eslint-disable-line class-methods-use-this
    let returnValue = '';
    if (ramType) {
      returnValue = ramType.toUpperCase();
    }
    return returnValue;
  }

  formatStorageType(serviceType) { // eslint-disable-line class-methods-use-this
    const returnValue = serviceType.toUpperCase();
    return returnValue;
  }

  formatServiceType(serviceType) { // eslint-disable-line class-methods-use-this
    const returnValue = serviceType.toUpperCase();
    return returnValue;
  }

  formatDate(offerDate) { // eslint-disable-line class-methods-use-this
    const date = moment(offerDate, 'x').format('YYYY-MM-DD');
    return date;
  }

  formatMonthlyBandwidth(monthlyBandwidth) { // eslint-disable-line class-methods-use-this
    // const returnValue = parseInt(monthlyBandwidth, 10).toLocaleString();
    const returnValue = parseFloat(monthlyBandwidth, 10).toFixed(2);
    return `${returnValue} TB`;
  }

  formatOfferRating(offerRating) { // eslint-disable-line class-methods-use-this
    let stars;
    switch (offerRating) {
      case 'zero':
        stars = (
          <>
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'one':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'two':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'three':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'four':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'five':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
          </>
        );
        break;
      default:
        stars = (
          <>
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
      //  break;
    }

    return stars;
  }

  formatTotalStorageCapacity(totalStorageCapacity) { // eslint-disable-line class-methods-use-this
    return `${totalStorageCapacity} GB`;
  }

  formatCostRangeValue(cost) { // eslint-disable-line class-methods-use-this
    // Create our number formatter.
    // alert(cost);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // These options are needed to round to whole numbers if that's what you want.

      // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // minimumFractionDigits: 0,

      // (causes 2500.99 to be printed as $2,501)
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    // formatter.format(2500); // $2,500.00
    return formatter.format(cost);
    // return `$${cost} USD`;
  }

  formatRamRangeValue(ram) { // eslint-disable-line class-methods-use-this
    const formattedRam = parseInt(ram, 10);
    return formattedRam.toLocaleString();
  }

  formatStorageRangeValue(storage) { // eslint-disable-line class-methods-use-this
    const formattedStorage = parseInt(storage, 10);
    return formattedStorage.toLocaleString();
  }

  formatCost(cost) { // eslint-disable-line class-methods-use-this
    return `$${cost} USD`;
  }

  isValidHttpUrl(string) { // eslint-disable-line class-methods-use-this
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}

export default OfferTemplateFunctions;
