import Settings from '../settings/settings';
// import AppState from '../appstate/appstate';

const util = require('util');

class Query {
  constructor(appState = null) {
    // this.offerFilterQueryFunction = null; // function that will get the offer filter query
    // this.loadOffersFunction = null;

    // this.getOfferFilterVariablesFunction = null;

    this.settings = new Settings();
    this.protocol = this.settings.getServerProtocol();
    this.host = this.settings.getServerHost();
    this.port = this.settings.getServerPort();

    // server api version
    this.v = this.settings.getServerVersion();

    if (appState === null) {
      this.token = this.settings.getClientToken();
      // console.log(`if token: ${this.token}`);
    } else {
      this.token = appState.getToken();
      // console.log(`else token: ${this.token}`);
    }
  }

  getBaseUrl() {
    // this.clientProtocol = 'https';
    // this.clientHost = 'lowendoffer.win';
    // this.clientPort = 3000;

    this.clientProtocol = this.settings.getClientProtocol();
    this.clientHost = this.settings.getClientHost();
    this.clientPort = this.settings.getClientPort();

    this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;
    return this.baseUrl;
  }

  getToken() {
    // return this.token || this.settings.getDefaultClientToken();
    return this.token;
  }

  /*
  getLoadOffersFunction() {
    return this.loadOffersFunction;
  }

  setLoadOffersFunction(loadOffersFunction) {
    this.loadOffersFunction = loadOffersFunction;
  }
  */

  getServerResendActivationLinkEndpointURL(email) {
    // {{protocol}}{{host}}:{{port}}/api/${this.v}
    // /public/user_resend_registration_email?email={email}
    this.serverResendActivationLinkEndpointURL = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/user_resend_registration_email?email=${email}`;
    return this.serverResendActivationLinkEndpointURL;
  }

  getUserOfferSchemaEndPoint() {
    this.userOfferSchemaEndpoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query={ __type(name: "Offer") { fields { name type { name } } } }`;
    return this.userOfferSchemaEndpoint;
  }

  getServerResetPasswordEndpoint() {
    // {{protocol}}{{host}}:{{port}}/api/${this.v}
    // /public/user_reset_password?
    this.serverResetPasswordEndpoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/user_reset_password?`;
  }

  getServerResendActivationLinkEndpoint() {
    // {{protocol}}{{host}}:{{port}}/api/${this.v}/
    // public/user_resend_registration_email?email={{email}}
    this.serverResendActivationLinkEndpoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/user_reset_password?`;
  }

  getServerRequestResetPasswordEndpoint() {
    // {{protocol}}{{host}}:{{port}}/api/${this.v}
    // /public/user_request_reset_password_link?email={{email}}
    this.serverRequestResetPasswordEndpoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/user_request_reset_password_link`;
    return this.serverRequestResetPasswordEndpoint;
  }

  getServerRegisterEndPoint() {
    this.serverRegisterEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/user_register`;
    return this.serverRegisterEndPoint;
  }

  getLoginEndPoint() {
    // {{protocol}}{{host}}:{{port}}/api/${this.v}/public/user_login
    this.loginEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/user_login`;
    return this.loginEndPoint;
  }

  getOfferFilterQueryFunction() {
    return this.offerFilterQueryFunction;
  }

  setOfferFilterQueryFunction(offerFilterQueryFunction) {
    this.offerFilterQueryFunction = offerFilterQueryFunction;
  }

  getDeleteUserCompanyEndPoint(companyId = 0, token = this.getToken()) {
    let variables = {
      id: companyId,
      token,
    };
    variables = JSON.stringify(variables);
    const mutation = `mutation { deleteCompany( token: "${token}", id: ${companyId}) { id } }`;
    this.deleteUserCompanyEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${mutation}&variables=${variables}`;
    return this.deleteUserCompanyEndPoint;
  }

  getDeleteUserOfferEndPoint(
    offerId = 0,
    token = this.getToken(),
  ) {
    let variables = {
      id: offerId,
      token,
    };
    variables = JSON.stringify(variables);
    const mutation = `mutation { deleteOffer( token: "${token}", id: ${offerId}) { id } }`;
    this.deleteUserOfferEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${mutation}&variables=${variables}`;
    return this.deleteUserOfferEndPoint;
  }

  getDeleteUserEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation deleteUserMutation($token: String = "${this.getToken()}", $id: ID = "${userId}") { deleteUser(token: $token, id: $id) { id } }`;
    const operationName = 'deleteUserMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getDeleteCompanyEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation deleteCompanyMutation( $token: String = "${this.getToken()}", $id: ID = "${userId}") { deleteCompany( token: $token, id: $id ) { id } }`;
    const operationName = 'deleteCompanyMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getDeleteOfferEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation deleteOfferMutation($token: String = "${this.getToken()}", $id: ID = ${parseInt(userId, 10)}) { deleteOffer(token: $token, id: $id) { id } }`;
    const operationName = 'deleteOfferMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getUpdateUserRoleUserEndPoint(
    userId = 0,
    role = 'user',
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation updateUserRoleMutation($token: String = "${this.getToken()}", $id: ID = "${userId}", $role: String = "${role}")
                      { updateUser(token: $token, id: $id, role: $role) { id role } }`;
    const operationName = 'updateUserRoleMutation';
    const variables = JSON.stringify({
      token,
      id: userId,
      role,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getActivateCompanyEndPoint(
    companyId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation activateCompanyMutation($token: String = "${this.getToken()}", $id: ID = "${companyId}") { updateCompany(token: $token, id: $id, active: true) { id active } }`;
    const operationName = 'activateCompanyMutation';
    const variables = JSON.stringify({
      id: companyId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getInActivateCompanyEndPoint(
    companyId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation inActivateCompanyMutation($token: String = "${this.getToken()}", $id: ID = "${companyId}") { updateCompany(token: $token, id: $id, active: false) { id active } }`;
    const operationName = 'inActivateCompanyMutation';
    const variables = JSON.stringify({
      id: companyId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getActivateUserEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation activateUserMutation($token: String = "${this.getToken()}", $id: ID = "${userId}") { updateUser(token: $token, id: $id, active: true) { id active } }`;
    const operationName = 'activateUserMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getInActivateUserEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation inActivateUserMutation($token: String = "${this.getToken()}", $id: ID = "${userId}") { updateUser(token: $token, id: $id, active: "0") { id active } }`;
    const operationName = 'inActivateUserMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getActivateOfferEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation activateOfferMutation($token: String = "${this.getToken()}", $id: ID = "${userId}") { updateOffer(token: $token, id: $id, active: true) { id active } }`;
    const operationName = 'activateOfferMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getInActivateOfferEndPoint(
    userId = 0,
    token = this.getToken(),
  ) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const mutation = `mutation inActivateOfferMutation($token: String = "${this.getToken()}", $id: ID = "${userId}", $active: Boolean = false) { updateOffer(token: $token, id: $id, active: $active) { id active } }`;
    const operationName = 'inActivateOfferMutation';
    const variables = JSON.stringify({
      id: userId,
      token,
    });
    const body = JSON.stringify({
      query: mutation,
      operationName,
      variables,
    });
    const returnValue = {
      body,
      endPoint,
      operationName,
    };
    return returnValue;
  }

  getOfferByShortcodeQuery(shortcode) {
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;

    let query = `{ offers( shortcode: "%s" )
                   {
                     id, owner, company{ id name }, name, service_type, location_iso_3166_alpha_2_country_code,
                     location, latitude, longitude, order_link, yearly_cost, second_year_cost, monthly_cost, ram_amount, ram_type, cpu_cores_amount,
                     cpu_cores_type, cpu_type, cpu_speed, monthly_bandwidth, unmetered_traffic_inbound, unmetered_traffic_outbound,
                     external_network_port_speed, total_storage_capacity, storage_type,
                     ipv4_addresses, additional_ip4_available, ipv6_addresses, looking_glass_link, initial_offer_date,  expiration_date,
                     aup, control_panel_type, support_method, support_response_time, management_type, windows, custom_iso,  control_via_api,
                     root_access, ddos_protection, softaculous, notes, payment_methods, setup_time, setup_fee, offer_rating,
                     shortcode, last_modified, active} }`;
    query = query.replace('%s', shortcode);
    const operationName = null;
    const variables = null;
    const body = JSON.stringify({
      query,
      operationName,
      variables,
    });
    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getGoogleMapsKey() {
    this.googleMapsKey = 'AIzaSyDYwN1twEZBqDjg7lNdO2ebwd3_nFSIbkA';
    return this.googleMapsKey;
  }

  log(value, message = '') {
    const id = this.logValues.length;
    this.logValues.push({ id, message, value });
  }

  logify(value, message = '') {
    const id = this.logValues.length;
    this.logValues.push({ id, message: JSON.stringify(message), value });
  }

  getUserOfferEndPoint(
    userId = 1,
    token = this.getToken(), // eslint-disable-line no-unused-vars
  ) {
    this.userOfferQuery = `
    {
      offers(owner: %s) {
        id
        owner
        company{
          id
        }
        name
        service_type
        virtualization_type
        ram_amount
        ram_type
        ram_error_correction
        total_storage_capacity
        storage_type
        storage_disk_amount
        swap_amount
        cpu_cores_amount
        cpu_cores_type
        cpu_speed
        cpu_type
        gpu_count
        gpu_type
        external_network_port_speed
        monthly_bandwidth
        unmetered_traffic_inbound
        unmetered_traffic_outbound
        location_iso_3166_alpha_2_country_code
        location
        latitude
        longitude
        ipv4_addresses
        additional_ip4_available
        ipv6_addresses
        yearly_cost
        second_year_cost
        monthly_cost
        setup_fee
        looking_glass_link
        initial_offer_date
        expiration_date
        payment_methods
        notes
        order_link
        stock
        setup_time
        management_type
        support_method
        support_response_time
        control_panel_type
        windows
        custom_iso
        control_via_api
        root_access
        softaculous
        ddos_protection
        bgp_sessions
        kvm_over_ip
        operating_system
        aup
        offer_rating
        shortcode
        last_modified
        visibility
        active
      }
    }`;
    this.userOfferQuery = this.userOfferQuery.replace('%s', userId);
    this.userOfferEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${this.userOfferQuery}`;
    return this.userOfferEndPoint;
  }

  // @param {int} userId = owner of the companies
  getUserCompanyEndPoint(userId = 1) {
    this.userCompanyQuery = '{ companies( owner: %s ) { id name founded url country_code company_rating description notes image } }';
    this.userCompanyQuery = this.userCompanyQuery.replace('%s', userId);
    this.userCompanyEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${this.userCompanyQuery}`;
    return this.userCompanyEndPoint;
  }

  getUpdateUserCompanyEndPoint(formData, token = this.getToken()) {
    const mutation = 'mutation updateCompanyMutation($id: ID, $owner: Int = 0, $name: String = "New Company", $founded: String = "2021-01-01 00:00:00", $url: String = "", $country_code: String = "US", $company_rating: company_rating = unrated, $description: String = "", $notes: String = "", $image: String = "", $active: Boolean = false, $token: String = "") {'
                     + 'updateCompany(id: $id, owner: $owner, name: $name, founded: $founded, url: $url, country_code: $country_code, company_rating: $company_rating, description: $description, notes: $notes, image: $image, active: $active, token: $token) {'
                     + ' id owner name founded url country_code company_rating description notes image active } }';

    formData.token = token; // eslint-disable-line no-param-reassign
    const variables = JSON.stringify(formData);

    this.updateUserCompanyEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${mutation}&variables=${variables}`;
    return this.updateUserCompanyEndPoint;
  }

  getAddUserOfferEndPoint(
    formData,
    token = this.getToken(),
  ) { // eslint-disable-line 
    const mutation = `mutation addOfferMutation(
      $token: String = "",
      $owner: Int, 
      $company: Int = 0, 
      $name: String = "New Offer", 
      $service_type: service_type = vps,
      $virtualization_type: virtualization_type = kvm,
      $ram_amount: String = "1",
      $ram_type: ram_type = ddr3,
      $ram_error_correction: ram_error_correction = no_ecc,
      $total_storage_capacity: String = "5",
      $storage_type: storage_type = ssd, 
      $storage_disk_amount: Int = 1,
      $swap_amount: Int = 0,
      $cpu_cores_amount: Int = 1,
      $cpu_cores_type: cpu_cores_type = virtual,
      $cpu_speed: Int = 2000,
      $cpu_type: String = "",
      $gpu_count: Int = 0,
      $gpu_type: String = "",
      $external_network_port_speed: external_network_port_speed = fast_ethernet_100_mbps,
      $monthly_bandwidth: String = "1",
      $unmetered_traffic_inbound: Boolean = false,
      $unmetered_traffic_outbound: Boolean = false,
      $location_iso_3166_alpha_2_country_code: String = "US",
      $location: String = "",
      $latitude: String = "8.068021",
      $longitude: String = "12.5447264",
      $ipv4_addresses: String = "1", 
      $additional_ip4_available: Boolean = false,
      $ipv6_addresses: String = "",  
      $yearly_cost: String = "",
      $second_year_cost: String = "0",
      $monthly_cost: String = "",
      $setup_fee: String = "",
      $looking_glass_link: String = "", 
      $initial_offer_date: String = "2021-01-01 00:00:00", 
      $expiration_date: String = "2021-01-01 00:00:00", 
      $payment_methods: payment_methods = credit_card, 
      $notes: String = "", 
      $order_link: String = "",
      $stock: Int = 0,
      $setup_time: setup_time = few_minutes, 
      $management_type: management_type = self_managed, 
      $support_method: support_method = email, 
      $support_response_time: support_response_time = within_a_day, 
      $control_panel_type: control_panel_type = no_panel, 
      $windows: Boolean = false, 
      $custom_iso: Boolean = false, 
      $control_via_api: Boolean = false, 
      $root_access: Boolean = false, 
      $softaculous: Boolean = false, 
      $ddos_protection: Boolean = false, 
      $bgp_sessions: Boolean = false,
      $kvm_over_ip: Boolean = false,
      $operating_system: String = "",
      $aup: aup = none, 
      $offer_rating: offer_rating = unrated, 
      $shortcode: String = "", 
      $last_modified: String = "2021-01-01 00:00:00", 
      $visibility: visibility = public, 
      $active: Boolean = false, 
    )
  { 
  addOffer(
    token: $token,
    owner: $owner,
    company: $company,
    name: $name,
    service_type: $service_type,
    virtualization_type: $virtualization_type,
    ram_amount: $ram_amount,
    ram_type: $ram_type,
    ram_error_correction: $ram_error_correction,
    total_storage_capacity: $total_storage_capacity,
    storage_type: $storage_type,
    storage_disk_amount: $storage_disk_amount,
    swap_amount: $swap_amount,
    cpu_cores_amount: $cpu_cores_amount,
    cpu_cores_type: $cpu_cores_type,
    cpu_speed: $cpu_speed,
    cpu_type: $cpu_type,
    gpu_count: $gpu_count,
    gpu_type: $gpu_type,
    external_network_port_speed: $external_network_port_speed,
    monthly_bandwidth: $monthly_bandwidth,
    unmetered_traffic_inbound: $unmetered_traffic_inbound,
    unmetered_traffic_outbound: $unmetered_traffic_outbound,
    location_iso_3166_alpha_2_country_code: $location_iso_3166_alpha_2_country_code,
    location: $location,
    latitude: $latitude,
    longitude: $longitude,
    ipv4_addresses: $ipv4_addresses,
    additional_ip4_available: $additional_ip4_available,
    ipv6_addresses: $ipv6_addresses,
    yearly_cost: $yearly_cost,
    second_year_cost: $second_year_cost,
    monthly_cost: $monthly_cost,
    setup_fee: $setup_fee,
    looking_glass_link: $looking_glass_link,
    initial_offer_date: $initial_offer_date,
    expiration_date: $expiration_date,
    payment_methods: $payment_methods,
    notes: $notes,
    order_link: $order_link,
    stock: $stock,
    setup_time: $setup_time,
    management_type: $management_type,
    support_method: $support_method,
    support_response_time: $support_response_time,
    control_panel_type: $control_panel_type,
    windows: $windows,
    custom_iso: $custom_iso,
    control_via_api: $control_via_api,
    root_access: $root_access,
    softaculous: $softaculous,
    ddos_protection: $ddos_protection,
    bgp_sessions: $bgp_sessions,
    kvm_over_ip: $kvm_over_ip,
    operating_system: $operating_system,
    aup: $aup,
    offer_rating: $offer_rating,
    shortcode: $shortcode,
    last_modified: $last_modified,
    visibility: $visibility,
    active: $active,
  ) 
  {
    id
    owner
    company {
      id
    }
    name
    service_type
    virtualization_type
    ram_amount
    ram_type
    ram_error_correction
    total_storage_capacity
    storage_type
    storage_disk_amount
    swap_amount
    cpu_cores_amount
    cpu_cores_type
    cpu_speed
    cpu_type
    gpu_count
    gpu_type
    external_network_port_speed
    monthly_bandwidth
    unmetered_traffic_inbound
    unmetered_traffic_outbound
    location_iso_3166_alpha_2_country_code
    location
    latitude
    longitude
    ipv4_addresses
    additional_ip4_available
    ipv6_addresses
    yearly_cost
    second_year_cost
    monthly_cost
    setup_fee
    looking_glass_link
    initial_offer_date
    expiration_date
    payment_methods
    notes
    order_link
    stock
    setup_time
    management_type
    support_method
    support_response_time
    control_panel_type
    windows
    custom_iso
    control_via_api
    root_access
    softaculous
    ddos_protection
    bgp_sessions
    kvm_over_ip
    operating_system
    aup
    offer_rating
    shortcode
    last_modified
    visibility
    active
  }
}`;

    formData.token = token; // eslint-disable-line no-param-reassign
    const variables = JSON.stringify(formData);

    this.addUserOfferEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${mutation}&variables=${variables}`;
    return this.addUserOfferEndPoint;
  }

  getUpdateUserOfferData(
    formData,
    token = this.getToken(),
  ) { // eslint-disable-line 
    const query = `mutation updateOfferMutation(
  $token: String = "",
  $id: ID!,
  $owner: Int, 
  $company: Int = 0, 
  $name: String = "New Offer", 
  $service_type: service_type = vps,
  $virtualization_type: virtualization_type = kvm,
  $ram_amount: String = "1",
  $ram_type: ram_type = ddr3,
  $ram_error_correction: ram_error_correction = no_ecc,
  $total_storage_capacity: String = "5",
  $storage_type: storage_type = ssd, 
  $storage_disk_amount: Int = 1,
  $swap_amount: Int = 0,
  $cpu_cores_amount: Int = 1,
  $cpu_cores_type: cpu_cores_type = virtual,
  $cpu_speed: Int = 2000,
  $cpu_type: String = "",
  $gpu_count: Int = 0,
  $gpu_type: String = "",
  $external_network_port_speed: external_network_port_speed = fast_ethernet_100_mbps,
  $monthly_bandwidth: String = "1",
  $unmetered_traffic_inbound: Boolean = false,
  $unmetered_traffic_outbound: Boolean = false,
  $location_iso_3166_alpha_2_country_code: String = "US",
  $location: String = "",
  $latitude: String = "8.068021",
  $longitude: String = "12.5447264",
  $ipv4_addresses: String = "1", 
  $additional_ip4_available: Boolean = false,
  $ipv6_addresses: String = "",  
  $yearly_cost: String = "",
  $second_year_cost: String = "0",
  $monthly_cost: String = "",
  $setup_fee: String = "",
  $looking_glass_link: String = "", 
  $initial_offer_date: String = "2021-01-01 00:00:00", 
  $expiration_date: String = "2021-01-01 00:00:00", 
  $payment_methods: payment_methods = credit_card, 
  $notes: String = "", 
  $order_link: String = "",
  $stock: Int = 0,
  $setup_time: setup_time = few_minutes, 
  $management_type: management_type = self_managed, 
  $support_method: support_method = email, 
  $support_response_time: support_response_time = within_a_day, 
  $control_panel_type: control_panel_type = no_panel, 
  $windows: Boolean = false, 
  $custom_iso: Boolean = false, 
  $control_via_api: Boolean = false, 
  $root_access: Boolean = false, 
  $softaculous: Boolean = false, 
  $ddos_protection: Boolean = false, 
  $bgp_sessions: Boolean = false,
  $kvm_over_ip: Boolean = false,
  $operating_system: String = "",
  $aup: aup = none, 
  $offer_rating: offer_rating = unrated, 
  $shortcode: String = "", 
  $last_modified: String = "2021-01-01 00:00:00", 
  $visibility: visibility = public, 
  $active: Boolean = false, 
)
{ 
updateOffer(
  token: $token,
  id: $id,
  owner: $owner,
  company: $company,
  name: $name,
  service_type: $service_type,
  virtualization_type: $virtualization_type,
  ram_amount: $ram_amount,
  ram_type: $ram_type,
  ram_error_correction: $ram_error_correction,
  total_storage_capacity: $total_storage_capacity,
  storage_type: $storage_type,
  storage_disk_amount: $storage_disk_amount,
  swap_amount: $swap_amount,
  cpu_cores_amount: $cpu_cores_amount,
  cpu_cores_type: $cpu_cores_type,
  cpu_speed: $cpu_speed,
  cpu_type: $cpu_type,
  gpu_count: $gpu_count,
  gpu_type: $gpu_type,
  external_network_port_speed: $external_network_port_speed,
  monthly_bandwidth: $monthly_bandwidth,
  unmetered_traffic_inbound: $unmetered_traffic_inbound,
  unmetered_traffic_outbound: $unmetered_traffic_outbound,
  location_iso_3166_alpha_2_country_code: $location_iso_3166_alpha_2_country_code,
  location: $location,
  latitude: $latitude,
  longitude: $longitude,
  ipv4_addresses: $ipv4_addresses,
  additional_ip4_available: $additional_ip4_available,
  ipv6_addresses: $ipv6_addresses,
  yearly_cost: $yearly_cost,
  second_year_cost: $second_year_cost,
  monthly_cost: $monthly_cost,
  setup_fee: $setup_fee,
  looking_glass_link: $looking_glass_link,
  initial_offer_date: $initial_offer_date,
  expiration_date: $expiration_date,
  payment_methods: $payment_methods,
  notes: $notes,
  order_link: $order_link,
  stock: $stock,
  setup_time: $setup_time,
  management_type: $management_type,
  support_method: $support_method,
  support_response_time: $support_response_time,
  control_panel_type: $control_panel_type,
  windows: $windows,
  custom_iso: $custom_iso,
  control_via_api: $control_via_api,
  root_access: $root_access,
  softaculous: $softaculous,
  ddos_protection: $ddos_protection,
  bgp_sessions: $bgp_sessions,
  kvm_over_ip: $kvm_over_ip,
  operating_system: $operating_system,
  aup: $aup,
  offer_rating: $offer_rating,
  shortcode: $shortcode,
  last_modified: $last_modified,
  visibility: $visibility,
  active: $active,
) 
  {
    id
    owner
    company
    name
    service_type
    virtualization_type
    ram_amount
    ram_type
    ram_error_correction
    total_storage_capacity
    storage_type
    storage_disk_amount
    swap_amount
    cpu_cores_amount
    cpu_cores_type
    cpu_speed
    cpu_type
    gpu_count
    gpu_type
    external_network_port_speed
    monthly_bandwidth
    unmetered_traffic_inbound
    unmetered_traffic_outbound
    location_iso_3166_alpha_2_country_code
    location
    latitude
    longitude
    ipv4_addresses
    additional_ip4_available
    ipv6_addresses
    yearly_cost
    second_year_cost
    monthly_cost
    setup_fee
    looking_glass_link
    initial_offer_date
    expiration_date
    payment_methods
    notes
    order_link
    stock
    setup_time
    management_type
    support_method
    support_response_time
    control_panel_type
    windows
    custom_iso
    control_via_api
    root_access
    softaculous
    ddos_protection
    bgp_sessions
    kvm_over_ip
    operating_system
    aup
    offer_rating
    shortcode
    last_modified
    visibility
    active
  }
}`;

    formData.token = token; // eslint-disable-line no-param-reassign
    const variables = JSON.stringify(formData);
    const operationName = 'updateOfferMutation';

    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const returnValue = {
      endPoint,
      query,
      operationName,
      variables,
    };
    return returnValue;
  }

  getAddUserCompanyEndPoint(
    formData,
    token = this.getToken(), // eslint-disable-line no-unused-vars
  ) {
    const mutation = 'mutation addCompanyMutation($owner: Int = 0, $name: String = "New Company", $founded: String = "2021-01-01 00:00:00", $url: String = "", $country_code: String = "US", $company_rating: company_rating = unrated, $description: String = "", $notes: String = "", $image: String = "", $active: Boolean = false, $token: String = "") {'
                     + 'addCompany(owner: $owner, name: $name, founded: $founded, url: $url, country_code: $country_code, company_rating: $company_rating, description: $description, notes: $notes, image: $image, active: $active, token: $token) {'
                     + ' id owner name founded url country_code company_rating description notes image active } }';

    formData.token = token; // eslint-disable-line no-param-reassign
    const variables = JSON.stringify(formData);

    this.addUserCompanyEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${mutation}&variables=${variables}`;
    return this.addUserCompanyEndPoint;
  }

  getUserEndPoint(token = this.getToken()) {
    const query = '{ users { id username email password create_time website role activation_code reset_password_code active } }';

    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    // const operationName = '';
    const variables = { token };

    const body = JSON.stringify({
      query,
      // operationName,
      variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getUserCompanySchemaEndPoint() {
    this.userCompanySchemaEndpoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query={ __type(name: "Company") { fields { name type { name } } } }`;
    return this.userCompanySchemaEndpoint;
  }

  getUpdateUserEndPoint(values) {
    this.updateUserQuery = 'mutation { updateUser( %s ) { username email password website } }';
    this.updateUserQuery = this.updateUserQuery.replace(
      '%s',
      util.inspect(values).replaceAll('\'', '"').replace('{', '').replace('}', ''),
    );
    this.updateUserEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${this.updateUserQuery}`;
    return this.updateUserEndPoint;
  }

  getUserOfferJson() {
    return this.userCompanyJson;
  }

  setUserCompanyJson(json) {
    this.userCompanyJson = json;
  }

  getUserCompanyJson() {
    return this.userCompanyJson;
  }

  getCompanyPageEndPoint(
    token = this.getToken(),
  ) {
    const query = '{ companies( active: true, company_visibility: public ) { id name owner founded url country_code company_rating description notes image active } }';
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    // const operationName = '';
    const variables = { token };

    const body = JSON.stringify({
      query,
      // operationName,
      variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getCompanyEndPoint() {
    this.companyQuery = '{ companies { id name owner founded url country_code company_rating description notes image active } }';
    this.companyEndPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql?query=${this.companyQuery}`;
    return this.companyEndPoint;
  }

  getCompanyByIdQuery(
    companyId = 0,
  ) { // eslint-disable-line class-methods-use-this
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const query = `query companyByIdQuery($id: ID!) {
      company(id: $id) {
        name
        founded
        url
        country_code
        company_rating
        description
        notes
      }
    }`;
    const operationName = 'companyByIdQuery';
    const variables = { id: parseInt(companyId, 10) };

    const body = JSON.stringify({
      query,
      operationName,
      variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getOfferEndPoint() {
    const query = `{ offers { id, name, company{ id name }, service_type
                      location_iso_3166_alpha_2_country_code
                      location, latitude, longitude, order_link, yearly_cost
                      ram_amount, ram_type, cpu_cores_amount, cpu_cores_type
                      support_method, support_response_time
                      custom_iso, control_via_api, aup
                      cpu_type, monthly_bandwidth, external_network_port_speed
                      total_storage_capacity, storage_type, ipv4_addresses
                      ipv6_addresses, expiration_date, control_panel_type
                      management_type, windows, root_access, ddos_protection
                      softaculous, notes, payment_methods, setup_time
                      setup_fee, offer_rating, shortcode
                      last_modified, active } }`;
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    // const operationName = '';
    // const variables = {};

    const body = JSON.stringify({
      query,
      // operationName,
      // variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getOfferMapEndPoint(
    token = this.getToken(),
  ) {
    const query = `query offersMapQuery($token: String = "${token}") {
          offers ( token: $token, active: true, offset: 0, limit: 10000 ) { name, latitude, longitude, shortcode, yearly_cost, monthly_cost, active }
      }`;

    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    // const operationName = '';
    const variables = {};
    variables.token = token;

    const body = JSON.stringify({
      query,
      // operationName,
      variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getOfferFromCompanyEndPoint(
    companyId = 1,
    token = this.getToken(),
  ) {
    const query = `query offersFromCompany($token: String = "${token}", $company: Int = 0) {
          offers ( token: $token, active: true, offset: 0, limit: 10000, company: $company )
          {
            id
            owner
            company { id name }
            name
            service_type
            virtualization_type
            ram_amount
            ram_type
            ram_error_correction
            total_storage_capacity
            storage_type
            storage_disk_amount
            swap_amount
            cpu_cores_amount
            cpu_cores_type
            cpu_speed
            cpu_type
            gpu_count
            gpu_type
            external_network_port_speed
            monthly_bandwidth
            unmetered_traffic_inbound
            unmetered_traffic_outbound
            location_iso_3166_alpha_2_country_code
            location
            latitude
            longitude
            ipv4_addresses
            additional_ip4_available
            ipv6_addresses
            yearly_cost
            second_year_cost
            monthly_cost
            setup_fee
            looking_glass_link
            initial_offer_date
            expiration_date
            payment_methods
            notes
            order_link
            stock
            setup_time
            management_type
            support_method
            support_response_time
            control_panel_type
            windows
            custom_iso
            control_via_api
            root_access
            softaculous
            ddos_protection
            bgp_sessions
            kvm_over_ip
            operating_system
            aup
            offer_rating
            shortcode
            last_modified
            visibility
            active
          }
      }`;

    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const operationName = 'offersFromCompany';
    const variables = {};
    variables.token = token;
    variables.company = parseInt(companyId, 10);

    const body = JSON.stringify({
      query,
      operationName,
      variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }

  getOfferFilterQuery(
    filter,
    offset,
    limit,
    token = this.getToken(), // eslint-disable-line no-unused-vars
  ) {
    const filterJSON = JSON.stringify(filter); // eslint-disable-line no-unused-vars
    const filterCopy = JSON.parse(filterJSON); // eslint-disable-line no-unused-vars

    let variables = {};
    let query = '';

    if (filterCopy) {
      query = `query offersFilterQuery( $token: String = "", $offset: Int=0, $limit: Int=20, $filter: String = "" )
        {
          offersFilter( token: $token, limit: $limit, offset: $offset, filter: $filter )
          {
            id
            owner
            company{ id name founded notes description country_code company_rating image active }
            name
            service_type
            location_iso_3166_alpha_2_country_code
            location
            longitude
            latitude
            order_link
            yearly_cost
            second_year_cost
            monthly_cost
            ram_amount
            ram_type
            cpu_cores_amount
            cpu_cores_type
            cpu_type
            cpu_speed
            monthly_bandwidth
            external_network_port_speed
            total_storage_capacity
            storage_type
            ipv4_addresses
            additional_ip4_available
            ipv6_addresses
            looking_glass_link
            initial_offer_date
            expiration_date
            aup
            control_panel_type
            support_method
            support_response_time
            management_type
            windows
            custom_iso
            control_via_api
            root_access
            ddos_protection
            softaculous
            notes
            payment_methods
            setup_time
            setup_fee
            offer_rating
            shortcode
            last_modified
            active
          }
        }`;

      variables.token = token;
      variables.offset = offset;
      variables.limit = limit;
      variables.filter = filterJSON;
      variables = JSON.stringify(variables);
    }

    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/protected/graphql`;
    const operationName = 'offersFilterQuery';

    const body = JSON.stringify({
      variables,
      operationName,
      query,
    });

    const returnValue = {
      endPoint,
      body,
    };

    return returnValue;
  }

  getBuildVersionEndPoint(
    // token = this.getToken(),
  ) {
    const query = '';
    const endPoint = `${this.protocol}://${this.host}:${this.port}/api/${this.v}/public/version`;
    // const operationName = '';
    const variables = {};

    const body = JSON.stringify({
      query,
      // operationName,
      variables,
    });

    const returnValue = {
      endPoint,
      body,
    };
    return returnValue;
  }
}

export default Query;
