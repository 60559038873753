import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';
import OfferTemplateFunctions from './offertemplatefunctions';
import Fields from '../../../assets/fields.json';

const moment = require('moment'); // eslint-disable-line no-unused-vars

class AppOfferFilters extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.otf = new OfferTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query(this.appState);

    // this is used to reload offers when the filters are changed
    this.loadOffersFunction = null;

    this.clearFilters = this.clearFilters.bind(this);
    this.applyFliters = this.applyFliters.bind(this);

    this.loadFieldsDefault = this.loadFieldsDefault.bind(this);
    // this.loadFieldsLocalStorage = this.loadFieldsLocalStorage.bind(this);
    // this.saveFieldsLocalStorage = this.saveFieldsLocalStorage.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.setFilterState = this.setFilterState.bind(this);

    this.setRange = this.setRange.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setOptions = this.setOptions.bind(this);

    this.appState.setAppOfferFilters(this);

    this.state = {
      offset: 0, // eslint-disable-line react/no-unused-state
      limit: this.settings.getPageSizeLimit(), // eslint-disable-line react/no-unused-state
      fields: [],
    };
  }

  componentDidMount() {
    this.loadOffersFunction = this.appState.getLoadOffersFunction();

    this.loadFieldsDefault();
  }

  getState() {
    return this.state;
  }

  setRange(index, value) {
    const {
      fields,
    } = this.state;

    if (Array.isArray(fields)) {
      if (Array.isArray(value)) {
        if (value.length > 1 && fields.length > index) {
          fields[index].values = [value[0], value[1]];

          this.setState({
            fields,
          });
        }
      }
    }

    this.setFilterState();
  }

  setValue(index, value) {
    const {
      fields,
    } = this.state;

    fields[index].value = value;

    this.setState({
      fields,
    });

    this.setFilterState();
  }

  setOptions(index, value) {
    const {
      fields,
    } = this.state;

    fields[index].value = value;

    this.setState({
      fields,
    });

    this.setFilterState();
  }

  setFilterState() {
    const {
      fields,
    } = this.state;

    this.appState.setFilter(fields);
    this.appState.applyFliters();
  }

  applyFliters() {
    this.setFilterState();
    this.appState.applyFliters();
  }

  clearFilters() {
    this.loadFieldsDefault();
    this.applyFliters();
  }

  loadFieldsDefault() {
    const orignalFields = JSON.stringify(Fields);
    const fields = JSON.parse(orignalFields);

    this.setState({
      fields,
    });

    // this.setFilterState();
    this.appState.setFilter(fields);
  }

  renderFields() { // eslint-disable-line class-methods-use-this
    const returnValue = [];

    const {
      fields,
    } = this.state;

    const defaultGroupName = 'default';
    const groups = {};
    groups[defaultGroupName] = [];

    const className = 'p-inputtext p-component';

    // if (this.appState.role === 'super_admin') {
    // }

    if (Array.isArray(fields)) {
      for (let i = 0; i < fields.length; i += 1) {
        if (fields[i].active === 1 || fields[i].active === true) {
          switch (fields[i].type) {
            case 'range': {
              const label = (fields[i].label !== undefined && fields[i].label !== null) // eslint-disable-line no-unused-vars, max-len
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              if (Array.isArray(fields[i].values)) {
                if (fields[i].values.length === 0) {
                  fields[i].values.push([0, 100]);
                }
              } else {
                fields[i].values = [0, 100];
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: ${fields[i].values[0]} - ${(fields[i].values[1] === fields[i].max) ? 'Unlimited' : fields[i].values[1]}`}
                  </strong>

                  <Slider
                    range
                    max={fields[i].max}
                    value={[fields[i].values[0], fields[i].values[1]]}
                    onChange={(e) => {
                      this.setRange(i, e.value);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'integer': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: `}
                  </strong>
                  <input
                    className={className}
                    type="number"
                    id={fields[i].name}
                    name={fields[i].name}
                    min={fields[i].min}
                    max={fields[i].max}
                    value={fields[i].value}
                    step="1"
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      // this.setValue(i, e.value);
                      this.setValue(i, document.getElementById(fields[i].name).value);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'float': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: `}
                  </strong>
                  <input
                    className={className}
                    type="text"
                    name={fields[i].name}
                    min={fields[i].min}
                    max={fields[i].max}
                    value={fields[i].value}
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      // this.setValue(i, e.value);
                      this.setValue(i, document.getElementById(fields[i].name).value);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'string': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: `}
                  </strong>
                  <input
                    className={className}
                    id={fields[i].name}
                    type="text"
                    name={fields[i].name}
                    value={fields[i].value}
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      // this.setValue(i, e.value);
                      this.setValue(i, document.getElementById(fields[i].name).value);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'url': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: `}
                  </strong>
                  <input
                    className={className}
                    type="text"
                    name={fields[i].name}
                    value={fields[i].value}
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      this.setValue(i, e.value);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'timestamp': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: `}
                  </strong>
                  <input
                    className={className}
                    type="date"
                    name={fields[i].name}
                    min={fields[i].min}
                    max={fields[i].max}
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      this.setValue(i, e.value);
                      // this.setChecked(i, document.getElementById(fields[i].name).checked);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'boolean': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              const options = [];
              if (fields[i].value === 'any' || fields[i].value === '') {
                options.push((
                  <option selected value="any">Any</option>
                ));
              } else {
                options.push((
                  <option value="any">Any</option>
                ));
              }

              if (fields[i].value === 0 || fields[i].value === 'no') {
                options.push((
                  <option selected value="0">No</option>
                ));
              } else {
                options.push((
                  <option value="0">No</option>
                ));
              }

              if (fields[i].value === 1 || fields[i].value === 'yes') {
                options.push((
                  <option selected value="1">Yes</option>
                ));
              } else {
                options.push((
                  <option value="1">Yes</option>
                ));
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {label}
                  </strong>
                  <br />
                  <select
                    className={className}
                    id={fields[i].name}
                    name={fields[i].name}
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      const selectedOptions = document.getElementById(fields[i].name).selectedOptions; // eslint-disable-line prefer-destructuring, max-len
                      const values = Array.from(selectedOptions).map(({ value }) => value); // eslint-disable-line max-len, no-unused-vars
                      this.setOptions(i, values);
                    }}
                  >
                    {options}
                  </select>
                  <br />
                  <br />
                </>
              ));
              break;
            }
            case 'enum': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              const options = [];
              if (Array.isArray(fields[i].values)) {
                for (let c = 0; c < fields[i].values.length; c += 1) {
                  if (fields[i].values[c] === fields[i].value) {
                    options.push((
                      <option selected value={fields[i].values[c]}>{fields[i].values[c]}</option>
                    ));
                  } else {
                    options.push((
                      <option value={fields[i].values[c]}>{fields[i].values[c]}</option>
                    ));
                  }
                }

                if (fields[i].group !== undefined && fields[i].group !== null) {
                  if (groups[fields[i].group] === undefined) {
                    groups[fields[i].group] = [];
                  }
                } else {
                  fields[i].group = defaultGroupName;
                }

                groups[fields[i].group].push((
                  <>
                    <strong>
                      {label}
                    </strong>
                    <br />
                    <select
                      className={className}
                      id={fields[i].name}
                      name={fields[i].name}
                      onChange={(e) => { // eslint-disable-line no-unused-vars
                        const selectedOptions = document.getElementById(fields[i].name).selectedOptions; // eslint-disable-line prefer-destructuring, max-len
                        const values = Array.from(selectedOptions).map(({ value }) => value); // eslint-disable-line max-len, no-unused-vars
                        this.setOptions(i, values);
                      }}
                    >
                      {options}
                    </select>
                    <br />
                    <br />
                  </>
                ));
              }
              break;
            }
            case 'set': {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              const options = [];
              if (Array.isArray(fields[i].values)) {
                for (let c = 0; c < fields[i].values.length; c += 1) {
                  if (fields[i].value.indexOf(fields[i].values[c]) !== -1) {
                    options.push((
                      <option selected value={fields[i].values[c]}>{fields[i].values[c]}</option>
                    ));
                  } else {
                    options.push((
                      <option value={fields[i].values[c]}>{fields[i].values[c]}</option>
                    ));
                  }
                }

                if (fields[i].group !== undefined && fields[i].group !== null) {
                  if (groups[fields[i].group] === undefined) {
                    groups[fields[i].group] = [];
                  }
                } else {
                  fields[i].group = defaultGroupName;
                }

                groups[fields[i].group].push((
                  <>
                    <strong>
                      {label}
                    </strong>
                    <br />
                    <select
                      className={className}
                      id={fields[i].name}
                      name={fields[i].name}
                      multiple
                      onChange={(e) => { // eslint-disable-line no-unused-vars
                        const selectedOptions = document.getElementById(fields[i].name).selectedOptions; // eslint-disable-line prefer-destructuring, max-len
                        const values = Array.from(selectedOptions).map(({ value }) => value); // eslint-disable-line max-len, no-unused-vars
                        this.setOptions(i, values);
                      }}
                    >
                      {options}
                    </select>
                    <br />
                    <br />
                  </>
                ));
              }
              break;
            }
            default: {
              const label = (fields[i].label !== undefined && fields[i].label !== null)
                ? fields[i].label
                : this.otf.formatFieldName(fields[i].name);

              if (fields[i].group !== undefined && fields[i].group !== null) {
                if (groups[fields[i].group] === undefined) {
                  groups[fields[i].group] = [];
                }
              } else {
                fields[i].group = defaultGroupName;
              }

              groups[fields[i].group].push((
                <>
                  <strong>
                    {`${label}: `}
                  </strong>
                  <input
                    className={className}
                    type="text"
                    id={fields[i].name}
                    name={fields[i].name}
                    value={fields[i].value}
                    onChange={(e) => { // eslint-disable-line no-unused-vars
                      this.setValue(i, document.getElementById(fields[i].name).value);
                    }}
                  />
                  <br />
                  <br />
                </>
              ));
            }
          }
        }
      }
    }

    Object.keys(groups).forEach((group) => {
      if (groups[group].length > 0) {
        returnValue.push((
          <Accordion>
            <AccordionTab header={group.toUpperCase()}>
              {groups[group]}
            </AccordionTab>
          </Accordion>
        ));
      }
    });

    return returnValue;
  }

  render() {
    const fieldsJSX = this.renderFields();

    return (
      <div className="appFilter">
        <Accordion>
          <AccordionTab header="FILTER">
            {fieldsJSX}
            <Button
              label="Clear Filters"
              icon="pi pi-refresh"
              onClick={() => {
                this.clearFilters();
              }}
            />
            {' '}
            <Button
              label="Apply Filters"
              icon="pi pi-refresh"
              onClick={() => {
                this.applyFliters();
              }}
            />
          </AccordionTab>
        </Accordion>
      </div>
    );
  }
}

AppOfferFilters.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppOfferFilters.defaultProps = {
  parent: null,
};

export default AppOfferFilters;
