/*
import {
  generate,
  // validate,
  // parse,
  // format,
} from 'build-number-generator';
*/

import version from '../assets/version.json';

class Settings {
  static allowComments = true; // eslint-disable-line import/no-named-as-default, import/no-named-as-default-member, max-len

  constructor() {
    // clients are connecting to us at
    this.clientProtocol = Settings.REACT_APP_CLIENT_PROTOCOL(); // this.clientProtocol = 'https';
    this.clientHost = Settings.REACT_APP_CLIENT_HOST(); // this.clientHost = 'webservertube.com';
    this.clientPort = Settings.REACT_APP_CLIENT_PORT(); // this.clientPort = 5000;

    // server we are connecting to
    this.serverProtocol = Settings.REACT_APP_SERVER_PROTOCOL(); // this.serverProtocol = 'https';
    this.serverHost = Settings.REACT_APP_SERVER_HOST(); // this.serverHost = 'webservertube.com';
    this.serverPort = Settings.REACT_APP_SERVER_PORT(); // this.serverPort = 5001;

    this.serverVersion = 'v1';
    this.clientVersion = version.version;

    // this.buildNumber = generate(this.getClientVersion());
    this.buildNumber = this.getClientVersion();

    this.clientToken = null;
    this.defaultClientToken = Settings.REACT_APP_DEFAULT_CLIENT_TOKEN();

    // this.yearlyCostMaxValue = 1000000; // $US 1,000,000
    this.yearlyCostMaxValue = 1000; // $US 30,000
    this.ramAmountMaxValue = 1000; // GB
    this.totalStorageCapacityMaxValue = 1000000; // GB
    this.maxOfferLimit = 50000; // 50,000 offers // I really want the number of offers here

    this.pageSizeLimit = 50; // results at a time

    // must not be empty
    // this.rowsPerPageOptions = [20, 50, 100];
    this.rowsPerPageOptions = [10, 20, 50, 100, 500, 1000];

    this.googleMapsKey = 'AIzaSyCRHJ4bqeoqp8qerGiGIxZC_v33xfN1pQI';

    this.siteTitle = 'Web Server Tube';

    this.env = Settings.NODE_ENV();

    this.allowRegistration = false;

    // console.log(`this.env: ${this.env}`);
    // console.log(`process.env: ${JSON.stringify(process.env)}`);
    // console.log(`this.serverPort: ${this.serverPort}`);
  }

  static NODE_ENV() {
    return process.env.NODE_ENV;
  }

  static REACT_APP_CLIENT_PROTOCOL() {
    return process.env.REACT_APP_CLIENT_PROTOCOL || 'https';
  }

  static REACT_APP_CLIENT_HOST() {
    return process.env.REACT_APP_CLIENT_HOST || 'webservertube.com';
  }

  static REACT_APP_CLIENT_PORT() {
    return process.env.REACT_APP_CLIENT_PORT || 5000;
  }

  static REACT_APP_SERVER_PROTOCOL() {
    return process.env.REACT_APP_SERVER_PROTOCOL || 'https';
  }

  static REACT_APP_SERVER_HOST() {
    return process.env.REACT_APP_SERVER_HOST || 'webservertube.com';
  }

  static REACT_APP_SERVER_PORT() {
    return process.env.REACT_APP_SERVER_PORT || 5001;
  }

  static REACT_APP_DEFAULT_CLIENT_TOKEN() {
    return process.env.REACT_APP_DEFAULT_CLIENT_TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50Ijp7InVzZXJJZCI6MSwidXNlcm5hbWUiOiJkZWZhdWx0X2NsaWVudCIsImVtYWlsIjoiZGVmYXVsdF9jbGllbnRAd2Vic2VydmVydHViZS5jb20iLCJwYXNzd29yZCI6IjE1YjI5ODBlNDQwYjQ2ZmZjZmQxYzM1NTA3MDI2NDFiIiwid2Vic2l0ZSI6IndlYnNlcnZlcnR1YmUuY29tIiwiaXNzdWVkX2F0IjoiMjAyMS0wNy0yMCAwNDoyMzoxNiIsImV4cGlyYXRpb24iOiIyMDIxLTA3LTIwIDA0OjIzOjE2Iiwicm9sZSI6Imd1ZXN0IiwiY2xpZW50SXAiOiI6OmZmZmY6NzIuMjIuMTM1LjExMiIsInVzZXJJcCI6IiIsImlzc3VlciI6Imxlby1zZXJ2ZXIifSwiaWF0IjoxNjI2NzY5Mzk2fQ.JcEe0cPrKM8CURWqiDn5sqZVbqXs3d2Ph8_O-54Qyvc';
  }

  static BASE_URL() {
    const baseUrl = `${Settings.REACT_APP_CLIENT_PROTOCOL()}://${Settings.REACT_APP_CLIENT_HOST()}`;
    return baseUrl;
  }

  setClientToken(token) {
    this.defaultClientToken = token;
  }

  getClientToken() {
    let token = '';
    if (this.clientToken) {
      token = this.clientToken;
    } else {
      token = this.defaultClientToken;
    }
    return token;
  }

  getDefaultRowsPerPageOptions() {
    if (this.rowsPerPageOptions.length > 0) {
      return this.rowsPerPageOptions[0];
    }
    return 20;
  }

  getRowsPerPageOptions() {
    return this.rowsPerPageOptions;
  }

  getAllowComments() {
    return this.allowComments;
  }

  setAllowComments(allowComments) {
    this.allowComments = allowComments;
  }

  getGoogleMapsKey() {
    return this.googleMapsKey;
  }

  getEnvrionment() { // eslint-disable-line class-methods-use-this
    return process.env.NODE_ENV;
  }

  getBaseUrl() {
    this.clientProtocol = 'https';
    this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;

    switch (process.env.NODE_ENV) {
      case 'development':
        this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;
        break;
      case 'staging':
        this.clientPort = 443;
        this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/staging/`;
        break;
      case 'production':
        this.clientPort = 443;
        this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;
        break;
      default:
    }
    return this.baseUrl;
  }

  getBuildNumber() {
    return this.buildNumber;
  }

  getClientVersion() {
    return this.clientVersion;
  }

  getServerVersion() {
    return this.serverVersion;
  }

  getYearlyCostMaxValue() {
    return this.yearlyCostMaxValue;
  }

  getRamAmountMaxValue() {
    return this.ramAmountMaxValue;
  }

  getTotalStorageCapacityMaxValue() {
    return this.totalStorageCapacityMaxValue;
  }

  getPageSizeLimit() {
    return this.pageSizeLimit;
  }

  getMaxOfferLimit() {
    return this.maxOfferLimit;
  }

  static isDevelopmnet() {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    return false;
  }

  static isProduction() {
    if (process.env.NODE_ENV === 'production') {
      return true;
    }
    return false;
  }

  getClientProtocol() {
    return this.clientProtocol;
  }

  getClientHost() {
    return this.clientHost;
  }

  getClientPort() {
    return this.clientPort;
  }

  getServerProtocol() {
    return this.serverProtocol;
  }

  getServerHost() {
    return this.serverHost;
  }

  getServerPort() {
    return this.serverPort;
  }

  getDefaultClientToken() {
    return this.defaultClientToken;
  }

  getSiteTitle() {
    return this.siteTitle;
  }

  getAllowRegistration() {
    return this.allowRegistration;
  }
}

export default Settings;
