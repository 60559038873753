import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Settings from '../../../settings/settings';

class AppDebug extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();

    this.column2BodyTemplate = this.column1BodyTemplate.bind(this);
    this.loadWatchValues = this.loadWatchValues.bind(this);

    this.state = {
      watchValues: [],
    };
  }

  componentDidMount() {
    this.loadWatchValues();
    this.appState.setLogCallbackFunction(this.loadWatchValues);
  }

  loadWatchValues() {
    const watchValues = [
      { name: 'isLoggedin', value: this.appState.isLoggedin },
      { name: 'token', value: this.appState.token },
      { name: 'userId', value: this.appState.userId },
      { name: 'username', value: this.appState.username },
      { name: 'email', value: this.appState.email },
      { name: 'password', value: this.appState.password },
      { name: 'website', value: this.appState.website },
      { name: 'role', value: this.appState.role },
      { name: 'clientIp', value: this.appState.getUserIp() },
      { name: 'filter', value: JSON.stringify(this.appState.getFilter()) },
    ];
    this.setState({ watchValues });
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    let outputJSX = null;
    if (rowData.name === 'token') {
      outputJSX = (
        <>
          <InputText
            value={rowData.value}
            style={{ width: '100%' }}
            onChange={() => {
            }}
          />
        </>
      );
    } else if (rowData.name === 'filter') {
      outputJSX = (
        <>
          <InputTextarea
            rows={15}
            cols={30}
            value={rowData.value}
            style={{ width: '100%' }}
            onChange={() => {
            }}
          />
        </>
      );
    } else {
      outputJSX = (
        <>
          {`${rowData.value}`}
        </>
      );
    }

    return (
      <>
        {outputJSX}
      </>
    );
  }

  render() {
    const {
      isLoggedin,
      logValues, // eslint-disable-line no-unused-vars
    } = this.appState;

    // alert(JSON.stringify(logValues));
    // console.log(JSON.stringify(logValues));

    const {
      watchValues,
    } = this.state;

    return (
      <>
        {/* (this.appState.role !== 'super_admin' && isLoggedin) */}
        {/* (0 && isLoggedin) */}
        { (this.appState.role !== 'super_admin' && isLoggedin)
          ? <></>
          : (
            <Accordion>
              <AccordionTab header="Debug">
                <div className="appDebug">
                  {`Envrionment: ${this.settings.getEnvrionment()}`}
                  <br />
                  <h3>Debug Info</h3>
                  <br />
                  <h3>Log</h3>
                  {/* */}
                  <DataTable
                    value={logValues}
                    style={{ width: '100%' }}
                  >
                    <Column
                      key="id"
                      field="id"
                      header="id"
                      style={{ width: '3vw' }}
                    />
                    <Column
                      key="key"
                      field="key"
                      header="key"
                      style={{ width: '17vw' }}
                    />
                    <Column
                      key="value"
                      field="value"
                      header="Value"
                      style={{ width: '70vw' }}
                    />
                  </DataTable>
                  {/* */}
                  <br />
                  <DataTable
                    value={watchValues}
                  >
                    <Column
                      key="name"
                      field="name"
                      header="Name"
                    />
                    <Column
                      key="value"
                      field="value"
                      header="Value"
                      body={this.column2BodyTemplate}
                      className="webkitScrollbarNone"
                    />
                  </DataTable>
                  <br />
                  <Button
                    label="Reload Watch Values"
                    icon="pi pi-refresh"
                    onClick={() => this.loadWatchValues()}
                  />
                </div>
              </AccordionTab>
            </Accordion>
          )}
      </>
    );
  }
}

AppDebug.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppDebug.defaultProps = {
  parent: null,
};

export default AppDebug;
