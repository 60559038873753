import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Utility from '../utility/utility';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;

    this.handleChange = this.handleChange.bind(this);
    this.handleRequestPasswordResetCode = this.handleRequestPasswordResetCode.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);

    const email = Utility.getQueryVariable('email') || '';
    const resetPasswordCode = Utility.getQueryVariable('reset_password_code') || '';

    const newPassword = '';
    const newPassword2 = '';

    this.state = {
      email,
      resetPasswordCode,
      newPassword,
      newPassword2,
    };
  }

  // called when component is first created
  componentDidMount() {
  }

  // called when component is removed
  componentWillUnmount() {
  }

  handleChange(e) {
    e.preventDefault();

    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  handleResetPassword(e) {
    e.preventDefault();

    const {
      email,
      resetPasswordCode,
      newPassword,
      newPassword2,
    } = this.state;

    if (newPassword === newPassword2) {
      const body = JSON.stringify({
        email,
        new_password: newPassword,
        reset_password_code: resetPasswordCode,
      });
      const { serverResetPasswordEndpoint } = this.appState;
      const serverRequestResetPasswordEndpointURL = `${serverResetPasswordEndpoint}`;

      fetch(serverRequestResetPasswordEndpointURL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `client ${this.appState.getToken()}`,
          },
          body,
        }).then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              console.log(`json: ${JSON.stringify(json)}`);
              if (json.passwordIsReset) {
                document.getElementById('resetPasswordResult').innerHTML = `${json.message}`;
              } else {
                document.getElementById('resetPasswordResult').innerHTML = `${json.message}`;
              }
            },
          );
        }
      }).catch(
        (err) => {
          console.error(`serverRequestResetPasswordEndpointURL Fetch Error: ${err}`);
        },
      );
    } else {
      document.getElementById('resetPasswordResult').innerHTML = 'Passwords do not match';
    }
  }

  handleRequestPasswordResetCode(e) {
    e.preventDefault();

    const { serverRequestResetPasswordEndpoint } = this.appState;

    const { email, resetPasswordCode } = this.state;
    const body = JSON.stringify({ email, resetPasswordCode });

    const { parent } = this;
    const { appState } = parent;
    appState.email = email;

    const
      serverRequestResetPasswordEndpointURL = `${serverRequestResetPasswordEndpoint}?email=${email}`;

    fetch(serverRequestResetPasswordEndpointURL,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              console.log(`handleSubmit json.message: ${json.message}`);
              document.getElementById('resetPasswordResult').innerHTML = `${json.message}`;
            },
          );
        }
      })
      .catch(
        (err) => {
          console.error(`serverRequestResetPasswordEndpointURL Fetch Error: ${err}`);
        },
      );
  }

  render() {
    const { isLoggedin } = this.appState;
    const {
      email,
      resetPasswordCode,
      newPassword,
      newPassword2,
    } = this.state;

    if (isLoggedin === true) {
      return (
        <div>
          You are already logged in.
          <br />
          {/* ` Token: ${this.appState.token}` */}
          <br />
          <a href="/logout">Log out</a>
          {/*  */}
        </div>
      );
    }

    if (!Utility.isEmpty(resetPasswordCode)) {
      console.log(`resetPasswordCode: ${resetPasswordCode}`);
      return (
        <>
          <div className="FormCenter loginGroup">
            <form onSubmit={this.handleResetPassword} className="FormFields">
              <div className="FormField">
                <TextField type="password" id="newPassword" className="FormField__Input" placeholder="Enter your new password" name="newPassword" value={newPassword} onChange={this.handleChange} label="New Password" variant="filled" />
                <br />
                <TextField type="password" id="newPassword2" className="FormField__Input" placeholder="Re-Enter your new password" name="newPassword2" value={newPassword2} onChange={this.handleChange} label="Re Enter New Password" variant="filled" />
              </div>
              <br />
              <div className="FormField">
                <Button variant="contained" type="submit">Reset Password</Button>
                <br />
              </div>
              <div id="resetPasswordResult" className="error" />
            </form>
          </div>
          <br />
        </>
      );
    }

    return (
      <>
        <div className="FormCenter loginGroup">
          <form onSubmit={this.handleRequestPasswordResetCode} className="FormFields">
            <div className="FormField">
              <TextField type="email" id="email" className="FormField__Input" placeholder="Enter your email" name="email" value={email} onChange={this.handleChange} label="Email Address" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <Button variant="contained" type="submit">Request New Password</Button>
              <br />
            </div>
          </form>
          <br />
          <a href="login">Login</a>
          <br />
          <a href="register">Register</a>
          <br />
          <div id="resetPasswordResult" className="error" />
        </div>
        <br />
      </>
    );
  }
}

ForgotPasswordForm.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

ForgotPasswordForm.defaultProps = {
  parent: null,
};

export default ForgotPasswordForm;
