import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Form from '@rjsf/material-ui';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/nova/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
import Query from '../../../query/query';
import Settings from '../../../settings/settings';
import CompanyTemplateFunctions from './companytemplatefunctions';

const moment = require('moment');

class AppUserCompanies extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.ctf = new CompanyTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query(this.appState);

    // datatable templates
    // this.foundedBodyTemplate = this.foundedBodyTemplate.bind(this);
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);
    this.column2BodyTemplate = this.column2BodyTemplate.bind(this);

    // load
    this.loadUserCompanies = this.loadUserCompanies.bind(this);
    this.reloadCompanies = this.reloadCompanies.bind(this);

    // add
    this.handleShowAddUserCompaniesForm = this.handleShowAddUserCompaniesForm.bind(this);
    this.handleHideAddUserCompaniesForm = this.handleHideAddUserCompaniesForm.bind(this);
    this.handleAddUserCompanyFormSubmit = this.handleAddUserCompanyFormSubmit.bind(this);

    // update
    this.handleHideUpdateUserCompanyForm = this.handleHideUpdateUserCompanyForm.bind(
      this,
    );
    this.handleShowUpdateUserCompanyForm = this.handleShowUpdateUserCompanyForm.bind(
      this,
    );
    this.handleUpdateUserCompanyFormSubmit = this.handleUpdateUserCompanyFormSubmit.bind(
      this,
    );

    this.setUpdateCompanyformData = this.setUpdateCompanyformData.bind(this);
    this.handleDeleteUserCompany = this.handleDeleteUserCompany.bind(this);

    this.loadUserCompanyFormsSchema = this.loadUserCompanyFormsSchema.bind(this);

    // this.loadUserCompanies();

    const addCompanyFormSchema = {
      type: 'object',
      required: ['name'],
    };

    const updateCompanyFormSchema = {
      type: 'object',
      required: ['name'],
    };

    const uiSchema = {
      owner: {
        // 'ui:widget': 'hidden',
      },
      url: {
        'ui:placeholder': 'http://',
      },
      notes: {
        'ui:widget': 'textarea',
      },
      description: {
        'ui:widget': 'textarea',
      },
    };

    this.state = {
      userCompanyColumns: [],
      userCompanyRows: [],
      addCompanyFormSchema,
      updateCompanyFormSchema,
      /*
      viewFieldsToIgnore: [
        'id',
        'founded',
        'country_code',
        'rating',
        'active',
      ],
      */
      updateFieldsToIgnore: ['active'],
      addFieldsToIgnore: ['id', 'active'],
      addCompanyformData: {},
      updateCompanyformData: {},
      uiSchema,
    };
  }

  componentDidMount() {
    this.loadUserCompanies();
    this.loadUserCompanyFormsSchema();
  }

  handleAddUserCompanyFormSubmit(data, event) {
    event.preventDefault();

    const body = JSON.stringify({});
    // this.appState.logify(
    //   'addUserCompanyEndPoint',
    //   addUserCompanyEndPoint,
    // );

    // console.log(this.appState.getAddUserCompanyEndPoint(data.formData));
    // fetch(this.appState.getAddUserCompanyEndPoint(data.formData),
    const addUserCompanyEndPoint = this.query.getAddUserCompanyEndPoint(data.formData);
    console.log(`addUserCompanyEndPoint: ${addUserCompanyEndPoint}`);
    fetch(addUserCompanyEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              document.getElementById('addCompanyResult').innerHTML = JSON.stringify(json);
              document.getElementById('hideAddUserCompaniesFormButton').click();
              this.reloadCompanies();
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
    this.loadUserCompanyFormsSchema();
    this.loadUserCompanies();
    this.setState({});
  }

  handleShowAddUserCompaniesForm(e) {
    e.preventDefault();
    const {
      // companySchema,
      addCompanyFormSchema,
      addCompanyformData,
      uiSchema,
    } = this.state;
    const element = (
      <div>
        <Button
          id="hideAddUserCompaniesFormButton"
          variant="contained"
          onClick={(event) => { this.handleHideAddUserCompaniesForm(event); }}
        >
          Hide Form
        </Button>
        <br />
        <Form
          formData={addCompanyformData}
          onSubmit={(data, event) => { this.handleAddUserCompanyFormSubmit(data, event); }}
          schema={addCompanyFormSchema}
          uiSchema={uiSchema}
        />
        <div id="addCompanyResult" />
        <br />
      </div>
    );
    // document.getElementById('hideUpdateUserCompaniesFormButton').click();
    ReactDOM.render(element, document.getElementById('addCompanyForm'));
    this.setState({});
  }

  handleHideAddUserCompaniesForm(e) {
    e.preventDefault();
    const element = (<div />);
    ReactDOM.render(element, document.getElementById('addCompanyForm'));
    this.setState({});
  }

  handleUpdateUserCompanyFormSubmit(data, event) {
    event.preventDefault();

    // const body = JSON.stringify({});
    // console.log(`fetch: ${this.appState.getUpdateUserCompanyEndPoint(data.formData)}`);
    // fetch(this.appState.getUpdateUserCompanyEndPoint(data.formData),
    const body = JSON.stringify({});
    const updateUserCompanyEndPoint = this.query.getUpdateUserCompanyEndPoint(data.formData);
    console.log(`updateUserCompanyEndPoint: ${updateUserCompanyEndPoint}`);
    fetch(updateUserCompanyEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              document.getElementById('hiddenResult').innerHTML = JSON.stringify(json);
              document.getElementById('hideUpdateUserCompaniesFormButton').click();
              this.reloadCompanies();
              this.setState({});
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
    this.loadUserCompanyFormsSchema();
    this.loadUserCompanies();
    this.setState({});
  }

  handleShowUpdateUserCompanyForm(e, id = 0) {
    e.preventDefault();
    if (id === 0) {
      return;
    }

    this.setUpdateCompanyformData(id);

    window.scrollTo(0, 0);

    const {
      updateCompanyFormSchema,
      updateCompanyformData,
      uiSchema,
    } = this.state;

    // alert(`updateCompanyformData: ${JSON.stringify(updateCompanyformData)}`);

    const element = (
      <div>
        <Button
          id="hideUpdateUserCompaniesFormButton"
          variant="contained"
          onClick={(event) => { this.handleHideUpdateUserCompanyForm(event); }}
        >
          Hide Form
        </Button>
        <br />
        <Form
          formData={updateCompanyformData}
          onSubmit={(data, event) => { this.handleUpdateUserCompanyFormSubmit(data, event); }}
          schema={updateCompanyFormSchema}
          uiSchema={uiSchema}
        />
        <br />
      </div>
    );
    ReactDOM.render(element, document.getElementById('updateCompanyForm'));
    this.setState({});
  }

  handleHideUpdateUserCompanyForm(e) {
    e.preventDefault();
    const element = (<div />);
    ReactDOM.render(element, document.getElementById('updateCompanyForm'));
    this.setState({});
  }

  handleDeleteUserCompany(e, id = 0) {
    e.preventDefault();

    const body = JSON.stringify({});

    // fetch(this.appState.getDeleteUserCompanyEndPoint(id),
    const deleteUserCompanyEndPoint = this.query.getDeleteUserCompanyEndPoint(id);
    console.log(`deleteUserCompanyEndPoint: ${deleteUserCompanyEndPoint}`);
    fetch(deleteUserCompanyEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              document.getElementById('deleteCompanyResult').innerHTML = JSON.stringify(json);
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
    this.loadUserCompanyFormsSchema();
    this.loadUserCompanies();
    this.setState({});
  }

  setUpdateCompanyformData(id = 0) {
    if (id === 0) {
      return;
    }

    const {
      userCompanyRows,
      updateCompanyFormSchema,
    } = this.state;

    const {
      userId,
    } = this.appState;

    const updateCompanyformData = {};
    const { properties } = updateCompanyFormSchema;
    const propertiesKeys = Object.keys(properties);

    // alert(`updateCompanyFormSchema: ${JSON.stringify(updateCompanyFormSchema.properties)}`);

    userCompanyRows.forEach((row) => {
      if (row.id === id) {
        // alert(`row: ${JSON.stringify(row)}`);
        propertiesKeys.forEach((column) => {
          switch (column) {
            case 'id':
              updateCompanyformData[column] = parseInt(row[column], 10);
              break;
            case 'owner':
              // updateCompanyformData[column] = parseInt(row[column], 10);
              updateCompanyformData[column] = parseInt(userId, 10);
              break;
            case 'founded':
              updateCompanyformData[column] = moment(row[column], 'x').format('YYYY-MM-DD hh:mm:ss');
              break;
            /*
            case 'company_rating':
              // must be enclosed in `` to be later stripped and sent to graphql query
              // values.default = '`one`';
              // values.enum = ['`one`', '`two`', '`three`', '`four`', '`five`'];
              updateCompanyformData[column] = `\${row[column]}\`;
              break;
            */
            case 'actions':
              break;
            default:
              updateCompanyformData[column] = row[column] || '';
          }
        });
      }
    });
    // alert(`updateCompanyformData" ${JSON.stringify(updateCompanyformData)}`);
    this.state.updateCompanyformData = updateCompanyformData;
  }

  loadUserCompanyFormsSchema() {
    const {
      uiSchema,
      addFieldsToIgnore,
      updateFieldsToIgnore,
      addCompanyFormSchema,
      updateCompanyFormSchema,
    } = this.state;

    const {
      userId,
    } = this.appState;

    addCompanyFormSchema.properties = {};
    updateCompanyFormSchema.properties = {};

    // console.log(`this.appState.getUserCompanySchemaEndPoint():
    // ${this.appState.getUserCompanySchemaEndPoint()}`);
    const body = JSON.stringify({});
    // fetch(this.appState.getUserCompanySchemaEndPoint(),
    const userCompanySchemaEndPoint = this.query.getUserCompanySchemaEndPoint();
    console.log(`userCompanySchemaEndPoint: ${userCompanySchemaEndPoint}`);
    fetch(userCompanySchemaEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              const { data } = json;
              const { __type } = data; // eslint-disable-line no-underscore-dangle
              const { fields } = __type;
              const properties = {};

              fields.forEach((field) => {
                // if (fieldsToIgnore.indexOf(field.name) !== -1) {
                //  return;
                // }

                const values = {};
                switch (field.type.name) {
                  case 'String':
                    values.title = field.name;
                    values.type = 'string';
                    values.maxLength = 250;
                    break;
                  case 'ID':
                    values.title = field.name;
                    values.type = 'integer';
                    break;
                  case 'Int':
                    values.title = field.name;
                    values.type = 'integer';
                    break;
                  case 'Float':
                    values.title = field.name;
                    values.type = 'number';
                    break;
                  case 'Boolean':
                    values.title = field.name;
                    // alert(field.name);
                    // values.type = 'boolean';
                    values.type = 'string';
                    values.default = '`true`';
                    values.enum = ['`true`', '`false`'];
                    values.enumNames = ['true', 'false'];
                    break;
                  default:
                    values.title = field.name;
                    values.type = 'string';
                    values.maxLength = 250;
                    // values.minLength = 1;
                    // values.format = 'date-time'
                    // pattern: '^[a-z0-9-]+$'
                }

                switch (field.name) {
                  case 'id':
                    values.title = field.name;
                    values.type = 'integer';
                    values.readOnly = true;
                    // values.default = parseInt(userId, 10); // this.appState.userId
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'owner':
                    values.title = field.name;
                    values.type = 'integer';
                    values.readOnly = true;
                    values.default = parseInt(userId, 10); // this.appState.userId
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'name':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = 'New Company';
                    break;
                  case 'url':
                    // values.default = ;
                    values.title = field.name;
                    values.maxLength = 250;
                    // values.pattern =
                    // 'https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]'
                    // '{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)';
                    // eslint-disable-line no-useless-escape
                    break;
                  case 'company_rating':
                    values.title = field.name;
                    values.default = 'unrated';
                    values.enum = ['unrated', 'one', 'two', 'three', 'four', 'five'];
                    values.enumNames = ['unrated', 'one', 'two', 'three', 'four', 'five'];
                    break;
                  case 'founded':
                    values.title = field.name;
                    values.default = moment().format('YYYY-MM-DD hh:mm:ss');
                    // values.format = 'date-time';
                    break;
                  case 'country_code':
                    values.title = field.name;
                    values.default = 'US';
                    values.enum = [
                      'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BH', 'BS',
                      'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF',
                      'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR',
                      'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK',
                      'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU',
                      'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM',
                      'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR',
                      'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX',
                      'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG',
                      'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA',
                      'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC',
                      'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH',
                      'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE',
                      'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW',
                    ];
                    break;
                  default:
                }

                properties[field.name] = values;

                if (!addFieldsToIgnore.includes(field.name)) {
                  addCompanyFormSchema.properties[field.name] = values;
                }

                if (!updateFieldsToIgnore.includes(field.name)) {
                  updateCompanyFormSchema.properties[field.name] = values;
                }
              });

              // const { companySchema } = this.state;
              // companySchema.properties = properties;

              // alert(`addCompanyFormSchema:
              // ${JSON.stringify(addCompanyFormSchema.properties)}`);
              // alert(`updateCompanyFormSchema:
              // ${JSON.stringify(updateCompanyFormSchema.properties)}`);
              // document.getElementById('companySchemaResult').innerHTML = JSON.stringify(fields);

              this.setState({ // companySchema,
                uiSchema,
                addCompanyFormSchema,
                updateCompanyFormSchema,
              });
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  loadUserCompanies() {
    const {
      userId,
    } = this.appState;

    // const {
    //   fieldsToIgnore,
    // } = this.state;

    const body = JSON.stringify({});

    const userCompanyEndPoint = this.query.getUserCompanyEndPoint(userId);
    console.log(`userCompanyEndPoint: ${userCompanyEndPoint}`);
    fetch(userCompanyEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // let index = 0;
              if (json.data) {
                if (json.data.companies) {
                  if (json.data.companies.length > 0) {
                    json.data.companies.forEach((companyRow) => {
                      // companyRow.id = index; // eslint-disable-line no-param-reassign
                      companyRow.actions = ( // eslint-disable-line no-param-reassign
                        <div style={{ overflow: 'hidden' }}>
                          <Button
                            className="pi pi-pencil"
                            onClick={(event) => {
                              this.handleShowUpdateUserCompanyForm(event, companyRow.id);
                            }}
                          />
                          <br />
                          <br />
                          <Button
                            className="pi pi-trash"
                            onClick={(event) => {
                              this.handleDeleteUserCompany(event, companyRow.id);
                            }}
                          />
                        </div>
                      ); // eslint-disable-line no-param-reassign
                      // index += 1;
                    });

                    const companyJson = json.data.companies;

                    const userCompanyColumns = [];
                    const firstRow = companyJson[0];
                    const columns = Object.keys(firstRow);
                    columns.forEach((column) => {
                      // alert(`column: ${JSON.stringify(column)}`);
                      // if (fieldsToIgnore.includes(column)) {
                      //   return;
                      // }

                      userCompanyColumns.push({
                        field: column,
                        header: column,
                        key: column,
                        name: column,
                        filterable: true,
                        sortable: true,
                        editable: true,
                        autoLayout: true,
                      });
                    });

                    const userCompanyRows = json.data.companies;

                    // alert(`userCompanyRows: ${JSON.stringify(userCompanyRows)}`);

                    this.setState({ userCompanyColumns, userCompanyRows });
                  }
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  reloadCompanies() {
    this.loadUserCompanies();
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <div style={{ overflow: 'hidden' }}>
        {`${rowData.name}`}
        <br />
        {`${rowData.image}`}
        <br />
        {`Since: ${moment(rowData.founded, 'x').format('YYYY-MM-DD')}`}
        <br />
        {`Country: ${rowData.country_code}`}
        <br />
        {this.ctf.formatCompanyRating(rowData.company_rating)}
        <br />
        {/* <Button label="Details" href={`${rowData.url}`} icon="pi pi-external-link" /> */}
      </div>
    );
  }

  column2BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <div style={{ overflow: 'hidden' }}>
        {`${rowData.description}`}
        <br />
        {`${rowData.notes}`}
      </div>
    );
  }

  render() {
    const {
      userCompanyRows,
      userCompanyColumns,
    } = this.state;

    const dynamicColumns = userCompanyColumns.map( // eslint-disable-line no-unused-vars
      (col) => {
        let template = null;
        let columnStyle = { width: '3em' };
        switch (col.field) {
          case 'founded':
            template = this.foundedBodyTemplate;
            break;
          case 'id':
            // template = this.foundedBodyTemplate;
            columnStyle = { width: '0.4em' };
            break;
          default:
            template = null;
            columnStyle = { width: '3em' };
        }

        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={columnStyle}
            sortable
            filter
            reorderable
            body={template}
          />
        );
      },
    );

    return (
      <div className="appTable">
        <h3>Companies</h3>
        <br />
        <div>
          <Button
            variant="contained"
            onClick={(event) => { this.handleShowAddUserCompaniesForm(event); }}
          >
            Add Company
          </Button>
          <br />
          <br />
          <div id="addCompanyForm" />
        </div>

        <div>
          <br />
          <div id="updateCompanyForm" />
        </div>
        <br />
        { (Array.isArray(userCompanyColumns) && Array.isArray(userCompanyRows))
          ? (
            <div>
              <DataTable
                value={userCompanyRows}
                paginator
                className="p-datatable-responsive"
                rows={this.settings.getDefaultRowsPerPageOptions()}
                rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
              >
                <Column
                  key="name"
                  field="name"
                  header="name"
                  body={this.column1BodyTemplate}
                  sortable
                  filter
                  reorderable
                />
                <Column
                  key="description"
                  field="description"
                  header="description"
                  body={this.column2BodyTemplate}
                  sortable
                  reorderable
                />
                <Column
                  key="actions"
                  field="actions"
                  header="actions"
                  style={{ width: '20vw' }}
                />
              </DataTable>
            </div>
          )
          : <></> }
        <br />
        <div id="updateCompanyResult" />
        <br />
        <div id="resultDataGrid" />
        <br />
        <div id="companySchemaResult" />
        <br />
        <div id="deleteCompanyResult" />
        <br />
        <div className="displayNone" id="hiddenResult" />
        <br />
      </div>
    );
  }
}

AppUserCompanies.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppUserCompanies.defaultProps = {
  parent: null,
};

export default AppUserCompanies;
