import publicIp from 'public-ip';

class Utility {
  static die(errMsg) {
    if (errMsg) {
      console.error(errMsg);
    }
    process.exit(1);
  }

  static getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    // console.log(query); //"app=article&act=news_content&aid=160990"
    const vars = query.split('&');
    // console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=');
      // console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  static isEmpty(value) {
    if (typeof value !== 'undefined' && value) {
      return false; // value is not Empty
    }
    return true; // value is Empty
  }

  static async getUserIp() { // eslint-disable-line consistent-return
    try {
      const returnValue = await publicIp.v4({
        fallbackUrls: ['https://ifconfig.co/ip'],
      });
      return returnValue;
    } catch (error) {
      console.log(`utility.getUserIp - Error: ${error}`);
      return null;
    }
  }
}

export default Utility;
