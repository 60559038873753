import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';

class AppProfile extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query(this.appState);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    const {
      username,
      email,
      password,
      password2,
      website,
      isLoggedin,
    } = this.appState;

    this.state = {
      username,
      email,
      password,
      password2,
      website,
      isLoggedin,
    };
  }

  handleChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      // username,
      email,
      password,
      password2,
      website,
      // isLoggedin,
    } = this.state;

    if (password !== password2) {
      return;
    }

    const body = JSON.stringify({
      // username,
      email,
      password,
      website,
    });

    const values = {
      email,
      password,
      website,
    };

    const updateUserEndPoint = this.query.getUpdateUserEndPoint(values);
    console.log(`updateUserEndPoint: ${updateUserEndPoint}`);
    fetch(updateUserEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // document.getElementById('updateResult').innerHTML = `${JSON.stringify(json.data)}`;
              // alert(JSON.stringify(json.data.updateUser));

              const user = json.data.updateUser;
              if (user) {
                this.appState.setEmail(user.email);
                this.appState.setPasword(user.password);
                this.appState.setWebsite(user.website);
                // this.appState.setToken(token);

                this.setState({
                  email,
                  password,
                  password2,
                  website,
                });
              }
              // this.parent.setState({}); // re-render
            },
          );
        }
      })
      .catch(
        (err) => {
          // console.error(`loginEndPoint Fetch Error: ${err}`);
          this.appState.logify(`loginEndPoint Fetch Error: ${err}`, 'handleSubmit app profile');
        },
      );
  }

  render() {
    const {
      username,
      email,
      password,
      password2,
      website,
      isLoggedin,
    // } = this.appState;
    } = this.state;

    if (isLoggedin === true) {
      return (
        <div>
          You are already logged in.
          <br />
        </div>
      );
    }

    return (
      <div className="appProfile">
        Profile
        <div className="">
          <form onSubmit={this.handleSubmit} className="FormFields">
            <div className="FormField">
              <TextField type="text" disabled={false} id="username" className="FormField__Input" placeholder="Enter your username" name="username" value={username} onChange={this.handleChange} label="Username" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="email" id="email" className="FormField__Input" placeholder="Enter your email" name="email" value={email} onChange={this.handleChange} label="Email Address" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="password" id="password" className="FormField__Input" placeholder="Enter your password" name="password" value={password} onChange={this.handleChange} label="Password" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="password" id="password2" className="FormField__Input" placeholder="Re-Enter your password" name="password2" value={password2} onChange={this.handleChange} label="Re-Type Password" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="text" id="website" className="FormField__Input" placeholder="website" name="website" value={website} onChange={this.handleChange} label="Website" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <Button variant="contained" type="submit">Update</Button>
              <br />
            </div>
          </form>
          <div id="updateResult" />
        </div>
        <br />
      </div>
    );
  }
}

AppProfile.propTypes = {
  parent: PropTypes.object,
};

AppProfile.defaultProps = {
  parent: null,
};

export default AppProfile;
