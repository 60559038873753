import Settings from '../settings/settings';

const moment = require('moment'); // eslint-disable-line no-unused-vars
const md5 = require('md5');

class Network {
  constructor() {
    this.settings = new Settings();

    window.addEventListener('online', () => console.log('Became online'));
    window.addEventListener('offline', () => console.log('Became offline'));
  }

  // if cache exists
  //  invalidate cache if necessary
  //   if cache valid
  //     fetch from local storage
  //   else
  //     load from network
  //     save results in local storage
  // else
  //   load from network
  //   save results in local storage

  static async fetch(endPoint, options) { // eslint-disable-line class-methods-use-this, no-unused-vars, max-len
    const { body } = options; // eslint-disable-line no-unused-vars
    // cache key is endpoint + body string
    const key = `${md5(endPoint + JSON.stringify(body))}`;

    let cacheResult = localStorage.getItem(key);

    if (cacheResult !== null) {
      // jsonData.timestamp
      const cacheResultMatch = cacheResult.match(/(.+)\.(\d+)$/);

      if (cacheResultMatch.length > 2) {
        const cacheResultJSON = cacheResultMatch[1]; // eslint-disable-line no-unused-vars
        const cacheResultTimestamp = cacheResultMatch[2]; // eslint-disable-line no-unused-vars

        // the number of milliseconds elapsed since January 1, 1970
        const currentTimestamp = Date.now();

        // 1000 miliseconds = 1 second, 60 seconds = 1 minute, 60 minute = 1 hour
        const expiryTime = 60 * 1000; // 60 seconds
        if (currentTimestamp - cacheResultTimestamp > expiryTime) {
          console.log(`cache Invalid: ${currentTimestamp - cacheResultTimestamp}`);
          if (window.navigator.onLine) {
            let response = null;
            response = await fetch(endPoint, options);

            if (response.ok) {
              response.json().then(
                (json) => {
                  const value = `${JSON.stringify(json)}.${Date.now()}`;
                  localStorage.setItem(key, value);

                  const blob = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
                  const init = { status: 200, statusText: 'ok!' };
                  const newResponse = new Response(blob, init);
                  return newResponse;
                },
              );
            }
          }
        }

        // console.log(`Initially ${(window.navigator.onLine ? 'on' : 'off')}line`);
      }

      cacheResult = cacheResult.replace(/(\.\d+)$/gi, '');
      // console.log(`cacheResult: ${cacheResult}`);

      const blob = new Blob([JSON.stringify(JSON.parse(cacheResult), null, 2)], { type: 'application/json' });
      const init = { status: 200, statusText: 'ok!' };
      const response = new Response(blob, init);
      return response;
    } else { // eslint-disable-line no-else-return
      let response = null;
      response = await fetch(endPoint, options);

      if (response.ok) {
        response.json().then(
          (json) => {
            const value = `${JSON.stringify(json)}.${Date.now()}`;
            localStorage.setItem(key, value);

            const blob = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
            const init = { status: 200, statusText: 'ok!' };
            const newResponse = new Response(blob, init);
            return newResponse;
          },
        );
      }
    }

    let result = null;
    result = fetch(endPoint, options);
    return result;
  }
}

export default Network;
