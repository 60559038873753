import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Query from '../query/query';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;
    // this.settings = new Settings();
    this.query = new Query();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: '',
      password: '',
    };
  }

  // called when component is first created
  componentDidMount() {
  }

  // called when component is removed
  // componentWillUnmount() {
  // }

  handleChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    /*
    const {
      // email,
      // password,
      // isLoggedin,
      loginEndPoint,
    } = this.appState;
    */

    const { email, password } = this.state;

    const body = JSON.stringify({ email, password });

    const { parent } = this;
    const { appState } = parent;
    appState.email = email;
    appState.password = password;

    // fetch(loginEndPoint,
    const loginEndPoint = this.query.getLoginEndPoint();
    // console.log(`loginEndPoint ${loginEndPoint}`);
    fetch(loginEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.isLoggedin) {
                // this.setState({ isLoggedin: json.isLoggedin });
                // console.log(`json: ${JSON.stringify(json)}`);
                // alert(JSON.stringify(json));
                // alert(JSON.stringify(json));

                appState.token = json.token;
                appState.isLoggedin = json.isLoggedin;
                appState.userId = json.data.userId;
                appState.username = json.data.username;
                appState.website = json.data.website;
                appState.role = json.data.role;
                appState.saveToLocalStorage();
                this.parent.setState({}); // re-render
              } else {
                this.appState.isLoggedin = json.isLoggedin;
                document.getElementById('loginResult').innerHTML = `${json.message}`;
                this.parent.setState({}); // re-render
              }
            },
          );
        } else {
          // alert('fail');
          console.log('loginform.handleSubmit response not ok');
        }
      })
      .catch(
        (err) => { console.error(`loginEndPoint Fetch Error: ${err}`); },
      );
  }

  render() {
    // const { appState } = this.appState;
    const { email, password, isLoggedin } = this.appState;

    if (isLoggedin === true) {
      return (
        <div>
          You are now logged in.
          <br />
          <a href="/logout">Log out</a>
        </div>
      );
    }

    return (
      <>
        <div className="">
          <form onSubmit={this.handleSubmit} className="FormFields">
            <div className="FormField">
              <TextField type="email" id="email" className="FormField__Input" placeholder="Enter your email" name="email" value={email} onChange={this.handleChange} label="Email Address" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <TextField type="password" id="password" className="FormField__Input" placeholder="Enter your password" name="password" value={password} onChange={this.handleChange} label="Password" variant="filled" />
              <br />
            </div>
            <br />
            <div className="FormField">
              <Button variant="contained" type="submit">Sign In</Button>
              <br />
            </div>
          </form>
          <div id="loginResult" />
        </div>
        <br />
        <Link to="forgot-password">Forgot Password?</Link>
        <br />
        <div>Don&lsquo;t have an account?</div>
        <Link to="register">Register now</Link>
        <br />
      </>
    );
  }
}

LoginForm.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

LoginForm.defaultProps = {
  parent: null,
};

export default LoginForm;
