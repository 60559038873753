import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import Settings from '../settings/settings';
import Query from '../query/query';

// const moment = require('moment');

class Version extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query(this.appState);

    this.getBuildVersion = this.getBuildVersion.bind(this);
    this.update = this.update.bind(this);

    this.state = {
      version: null,
    };
  }

  componentDidMount() {
    this.getBuildVersion();
  }

  getBuildVersion() { // eslint-disable-line 
    const {
      endPoint,
      body,
    } = this.query.getBuildVersionEndPoint();

    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // alert(JSON.stringify(json));
              if (json.version) {
                // console.log(`version: ${json.version}`);
                this.setState({
                  version: json.version,
                });
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  update() { // eslint-disable-line class-methods-use-this
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  }

  render() {
    const {
      version,
    } = this.state;
    return (
      <>
        <h1>Version</h1>
        <br />
        {`version: ${version}`}
        <br />
        <br />
        <Button
          label="Clear Cache"
          icon="pi pi-refresh"
          onClick={() => {
            this.update();
          }}
        />
        <br />
      </>
    );
  }
}

Version.propTypes = {
  parent: PropTypes.object,
};

Version.defaultProps = {
  parent: null,
};

export default Version;
