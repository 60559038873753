import React, { Component } from 'react';
import Settings from '../../../settings/settings';
import Utility from '../../../utility/utility';

class AppFooter extends Component {
  constructor(props) {
    super(props);

    this.appState = props.parent.appState;
    this.settings = new Settings();

    this.userIp = null;

    this.currentYear = new Date().getFullYear();
    // let copy = React.string({js|\u00a9|js});
    this.copyright = `Copyright \u00A9 ${this.currentYear}`;
  }

  componentDidMount() {
    this.userIp = Utility.getUserIp();
    // alert(this.userIp);
  }

  render() {
    return (
      <div className="appFooter">
        <div className="grid">
          <div className="col-4">
            <strong>Server Info</strong>
            <br />
            {`API Version: ${this.settings.getServerVersion()}`}
          </div>
          <div className="col-4">
            <strong>UX</strong>
            <br />
            {/* `Client Build: ${this.settings.getClientVersion()}` */}
            {`Build: ${this.settings.getBuildNumber()}`}
          </div>
          <div className="col-4">
            <strong>Social</strong>
            <br />
          </div>
        </div>
        <br />
        <div className="grid">
          <div className="col-4">
            <strong>Menu</strong>
            <br />
            <a href={`${this.settings.getBaseUrl()}sitemap`}>Sitemap</a>
            <br />
            <a href={`${this.settings.getBaseUrl()}privacy-policy`}>Privacy Policy</a>
            <br />
            <a href={`${this.settings.getBaseUrl()}version`}>Version</a>
          </div>
          <div className="col-4">
            <strong>Admin</strong>
            <br />
            <a href={`${this.settings.getBaseUrl()}login`}>Login</a>
          </div>
          <div className="col-4">
            <strong>Client Info</strong>
            <br />
            {/* `ip: ${this.appState.getUserIp()}` */}
          </div>
        </div>
        <br />
        <div className="copyright">{this.copyright}</div>
        <br />
      </div>
    );
  }
}

export default AppFooter;
