import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import AppProfile from '../template/default/user/appprofile';
import AppUserOffers from '../template/default/offer/appuseroffers';
import AppUserCompanies from '../template/default/company/appusercompanies';
import AppAdmin from '../template/default/admin/appadmin';

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;
  }

  render() {
    const {
      isLoggedin,
      role,
    } = this.appState;

    return (
      <>
        <h2>Dashboard</h2>
        {(isLoggedin)
          ? (
            <Accordion>
              <AccordionTab header="My Profile">
                <>
                  {(isLoggedin) ? <div><AppProfile parent={this} /></div> : <div />}
                </>
              </AccordionTab>
              <AccordionTab header="My Offers">
                <>
                  {(isLoggedin) ? <div><AppUserOffers parent={this} /></div> : <div />}
                </>
              </AccordionTab>
              <AccordionTab header="My Companies">
                <>
                  {(isLoggedin) ? <div><AppUserCompanies parent={this} /></div> : <div />}
                </>
              </AccordionTab>
              <AccordionTab header="My Token">
                <strong>Token</strong>
                <br />
                <br />
                <InputText
                  id="userToken"
                  value={this.appState.token}
                  style={{ width: '95vw' }}
                  onClick={() => {
                    // e.preventDefault();
                    document.getElementById('userToken').select();
                    document.execCommand('copy');
                  }}
                  onChange={() => {
                  }}
                />
              </AccordionTab>
              {/* {((role === 'super_admin') || (role === 'guest')) ?
                <AccordionTab header="Admin"><> <AppAdmin parent={this} /></>
                </AccordionTab> : <div />} */}
              {((role === 'super_admin') || (role === 'admin')) ? <AccordionTab header="Admin"><><AppAdmin parent={this} /></></AccordionTab> : <div />}
            </Accordion>
          ) : (
            <>You must login.</>
          )}
      </>
    );
  }
}

DashboardPage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

DashboardPage.defaultProps = {
  parent: null,
};

export default DashboardPage;
