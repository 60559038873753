import React, { Component } from 'react';
import {
//  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Settings from '../../../settings/settings';
import Pages from '../../../assets/pages.json'; // eslint-disable-line no-unused-vars

class AppBody extends Component {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.appState = props.parent.appState;
    this.settings = new Settings();

    this.addPageRoutes = this.addPageRoutes.bind(this);

    this.noMatch = this.noMatch.bind(this);

    // alert(`window.location.pathname: ${window.location.pathname}`);

    this.state = {
      pageRoutes: [],
    };
  }

  componentDidMount() {
    this.addPageRoutes();
  }

  // will load routes from a json file, for example
  // <Route exact path="/privacy-policy" component={this.parent.Page} />
  // <Route exact path="/staging/privacy-policy" component={this.parent.Page} />
  addPageRoutes() {
    const pageRoutes = [];
    if (Array.isArray(Pages.pages)) {
      for (let i = 0; i < Pages.pages.length; i += 1) {
        pageRoutes.push((
          <Route exact path={Pages.pages[i].path} component={this.parent.Page} />
        ));
        pageRoutes.push((
          <Route exact path={`/staging${Pages.pages[i].path}`} component={this.parent.Page} />
        ));
      }
    }
    this.setState({ pageRoutes });
  }

  noMatch() { // eslint-disable-line class-methods-use-this
    const {
      pathname,
    } = window.location;

    return (
      <div>
        <h1>404</h1>
        <h3>
          No match for
          <code>
            {pathname}
          </code>
        </h3>
      </div>
    );
  }

  render() {
    const {
      pageRoutes,
    } = this.state;

    return (
      <Switch>
        {/*
          exact will match /home
          without exact it will match all/home
        */}
        <Route exact path="/" component={this.parent.Home} />
        <Route exact path="/staging/" component={this.parent.Home} />
        <Route exact path="/index.php" component={this.parent.Home} />
        <Route exact path="/staging/index.php" component={this.parent.Home} />

        <Route exact path="/home" component={this.parent.Home} />
        <Route exact path="/staging/home" component={this.parent.Home} />

        <Route exact path="/o" component={this.parent.Home} />
        <Route exact path="/staging/o" component={this.parent.Home} />

        <Route exact path="/login" component={this.parent.Login} />
        <Route exact path="/staging/login" component={this.parent.Login} />

        <Route exact path="/logout" component={this.parent.LogOutPage} />
        <Route exact path="/staging/logout" component={this.parent.LogOutPage} />

        <Route exact path="/register" component={this.parent.Register} />
        <Route exact path="/staging/register" component={this.parent.Register} />

        <Route exact path="/forgot-password" component={this.parent.ForgotPassword} />
        <Route exact path="/staging/forgot-password" component={this.parent.ForgotPassword} />

        <Route exact path="/dashboard" component={this.parent.Dashboard} />
        <Route exact path="/staging/dashboard" component={this.parent.Dashboard} />

        <Route exact path="/map" component={this.parent.MapPage} />
        <Route exact path="/staging/map" component={this.parent.MapPage} />

        <Route exact path="/providers" component={this.parent.CompanyPage} />
        <Route exact path="/staging/providers" component={this.parent.CompanyPage} />
        <Route exact path="/p" component={this.parent.CompanyPage} />
        <Route exact path="/staging/p" component={this.parent.CompanyPage} />

        <Route exact path="/p/:companyId" component={this.parent.OffersFromCompany} />
        <Route exact path="/staging/p/:companyId" component={this.parent.OffersFromCompany} />

        <Route exact path="/companies" component={this.parent.CompanyPage} />
        <Route exact path="/staging/companies" component={this.parent.CompanyPage} />

        {/*
        <Route exact path="/about-us" component={this.parent.Page} />
        <Route exact path="/staging/about-us" component={this.parent.Page} />

        <Route exact path="/contact-us" component={this.parent.Page} />
        <Route exact path="/staging/contact-us" component={this.parent.Page} />

        <Route exact path="/privacy-policy" component={this.parent.Page} />
        <Route exact path="/staging/privacy-policy" component={this.parent.Page} />
        */}

        {pageRoutes}

        <Route exact path="/o/:shortcode" component={this.parent.OfferDetailPage} />
        <Route exact path="/staging/o/:shortcode" component={this.parent.OfferDetailPage} />

        <Route exact path="/version" component={this.parent.VersionPage} />
        <Route exact path="/staging/version" component={this.parent.VersionPage} />

        <Route path="/sitemap" component={this.parent.Sitemap} />

        <Route path="*">
          {this.noMatch()}
        </Route>
      </Switch>
    );
  }
}

AppBody.propTypes = {
  parent: PropTypes.object,
};

AppBody.defaultProps = {
  parent: null,
};

export default AppBody;
