import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppOffers from '../template/default/offer/appoffers';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;

    const offers = 152;
    this.homePageMessage = `Choose from ${offers} offers.`;
  }

  render() {
    return (
      <>
        <div className="homeMessage">
          <h1>
            {this.homePageMessage}
          </h1>
          {'\u200B'}
        </div>
        <AppOffers parent={this} />
      </>
    );
  }
}

HomePage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

HomePage.defaultProps = {
  parent: null,
};

export default HomePage;
