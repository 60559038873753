import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toast } from 'primereact/toast';
import { GMap } from 'primereact/gmap';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';
import Network from '../../../network/network'; // eslint-disable-line no-unused-vars
import AppOfferFilters from './appofferfilters';
import OfferTemplateFunctions from './offertemplatefunctions';

class AppOffers extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.otf = new OfferTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query(this.appState);
    // this.network = new Network();

    // datatable templates
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);
    this.column2BodyTemplate = this.column2BodyTemplate.bind(this);
    this.column3BodyTemplate = this.column3BodyTemplate.bind(this);
    this.column4BodyTemplate = this.column4BodyTemplate.bind(this);
    this.column5BodyTemplate = this.column5BodyTemplate.bind(this);

    this.showToast = this.showToast.bind(this);

    // clear
    this.clearOffers = this.clearOffers.bind(this);

    this.applyFilters = this.applyFilters.bind(this);

    const offset = 0;
    // const limit = this.settings.getPageSizeLimit();
    const limit = 50;

    this.state = {
      offset, // eslint-disable-line react/no-unused-state
      limit, // eslint-disable-line react/no-unused-state
      offerColumns: [],
      offerRows: [],
      dialogList: {}, // each row will have a dialog that will show the full details of the offer
    };

    this.loadOffers = this.loadOffers.bind(this);
    this.loadMoreOffers = this.loadMoreOffers.bind(this);

    // pass this function to appstate which will pass it to appoffers
    // when filters are updated they will call appoffers.loadOffers()
    this.appState.setLoadOffersFunction(this.loadOffers);

    this.appState.setApplyFlitersFunction(this.applyFilters);

    // this.query.setLoadOffersFunction(this.loadOffers);

    this.onClick = this.onClick.bind(this);
    this.onHideDialog = this.onHideDialog.bind(this);
  }

  componentDidMount() {
    // this.loadOffers();
    this.loadMoreOffers();
  }

  onClick(id) {
    const {
      dialogList,
    } = this.state;

    dialogList[id] = true;
    this.setState(dialogList);
  }

  onHideDialog(id) {
    const {
      dialogList,
    } = this.state;

    dialogList[id] = false;
    this.setState(dialogList);
  }

  showToast(detail = 'Message Content', summary: 'Success Message', severity = 'messafe', life = 3000) {
    if (this.toast) {
      this.toast.show({
        severity,
        summary,
        detail,
        life,
      });
    }
  }

  applyFilters() {
    this.clearOffers();
    this.loadOffers();
  }

  clearOffers() {
    const offerColumns = [];
    const offerRows = [];
    const dialogList = {};

    this.setState({
      offerColumns,
      offerRows,
      dialogList,
    });
  }

  loadOffers() {
    const {
      dialogList,
      offset,
    } = this.state;

    const {
      endPoint,
      body,
    } = this.query.getOfferFilterQuery(
      this.appState.getFilter(),
      offset,
      this.appState.getAppOfferFilters().getState().limit,
      this.appState.getToken(),
    );

    // console.log(`appoffers.loadOffers body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // alert(JSON.stringify(json));
              if (json.data) {
                const offerJson = json.data.offersFilter;
                if (offerJson) {
                  if (offerJson.length > 0) {
                    // this.showToast('Loaded');

                    const offerColumns = [];
                    const firstRow = offerJson[0];
                    const columns = Object.keys(firstRow);
                    columns.forEach((column) => {
                      offerColumns.push({
                        field: column,
                        header: column,
                        key: column,
                        name: column,
                        filterable: true,
                        sortable: true,
                        editable: true,
                        autoLayout: true,
                      });
                    });

                    // const offerRows = json.data.offers;
                    const offerRows = json.data.offersFilter;

                    offerRows.forEach((row) => {
                      dialogList[row.id] = false;
                    });

                    this.setState({
                      offerColumns,
                      offerRows,
                      dialogList,
                    });
                  } else {
                    this.setState({
                      offerRows: [],
                    });
                  }
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  loadMoreOffers() {
    const {
      dialogList,
    } = this.state;

    let {
      offerRows,
      offset,
    } = this.state;

    if (offerRows) {
      offset = offerRows.length;
    } else {
      offset = 0;
    }

    const {
      endPoint,
      body,
    } = this.query.getOfferFilterQuery(
      this.appState.getFilter(),
      offset,
      this.appState.getAppOfferFilters().getState().limit,
      this.appState.getToken(),
    );

    // console.log(`body: ${JSON.stringify(body)}`);

    Network.fetch(endPoint,
    // fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // alert(JSON.stringify(json));
              if (json.data) {
                const offerJson = json.data.offersFilter;
                if (offerJson) {
                  if (offerJson.length > 0) {
                    // console.log(`json.data.offersFilter:
                    // ${JSON.stringify(json.data.offersFilter)}`);
                    // this.showToast('Loaded');

                    const offerColumns = [];
                    const firstRow = offerJson[0];
                    const columns = Object.keys(firstRow);
                    columns.forEach((column) => {
                      offerColumns.push({
                        field: column,
                        header: column,
                        key: column,
                        name: column,
                        filterable: true,
                        sortable: true,
                        editable: true,
                        autoLayout: true,
                      });
                    });

                    const newOfferRows = json.data.offersFilter;

                    newOfferRows.forEach((row) => {
                      dialogList[row.id] = false;
                    });

                    offerRows = offerRows.concat(newOfferRows);

                    this.setState({
                      offerColumns,
                      offerRows,
                      dialogList,
                      offset,
                    });
                  } else {
                    this.setState({
                      // offerRows: [],
                      offset,
                    });
                  }
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  column1BodyTemplate(rowData) {
    const {
      dialogList,
    } = this.state;

    const shortcodeLink = `${this.appState.getBaseUrl()}o/${rowData.shortcode}`;

    let orderLink = (rowData.order_link) ? rowData.order_link : '';
    orderLink = (orderLink.indexOf('://') === -1) ? `https://${orderLink}` : orderLink;
    orderLink = (this.otf.isValidHttpUrl(orderLink)) ? orderLink : '';

    let lookingGlassLink = (rowData.looking_glass_link) ? rowData.looking_glass_link : '';
    lookingGlassLink = (lookingGlassLink.indexOf('://') === -1) ? `https: //${lookingGlassLink}` : lookingGlassLink;
    lookingGlassLink = (this.otf.isValidHttpUrl(lookingGlassLink)) ? lookingGlassLink : '';

    const dialogHeader = rowData.name;
    const dialogStyle = { width: '95vw' };
    const dialogVisible = dialogList[rowData.id];
    const dialogFooter = this.renderFooter(rowData.id);
    const dialogHideFunction = () => this.onHideDialog(rowData.id);
    const dialogBreakPoints = { '960px': '75vw' };

    const mapOptions = {
      center: {
        // lat: parseFloat(rowData.latitude) || 36.890257,
        // lng: parseFloat(rowData.longitude) || 30.707417,
        lat: parseFloat(rowData.latitude),
        lng: parseFloat(rowData.longitude),
      },
      zoom: 3,
      type: 'terrain',
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
    };
    const mapOverlays = [
      new google.maps.Marker({ // eslint-disable-line no-undef
        position: {
          lat: parseFloat(rowData.latitude),
          lng: parseFloat(rowData.longitude),
        },
        title: rowData.name,
        draggable: false,
      }),
    ];
    const googleMapsReady = true;

    return (
      <>
        <strong><a href={shortcodeLink} target="_top">{`${rowData.name}`}</a></strong>
        {' - '}
        {` ${this.otf.formatServiceType(rowData.service_type)} `}
        <br />
        {`${rowData.cpu_cores_amount} ${rowData.cpu_cores_type} Core(s) ${rowData.cpu_type} ${this.otf.formatCpuSpeed(rowData.cpu_speed)}`}
        <br />
        {(rowData.company)
          ? (
            <>
              {this.otf.formatCompanyName(
                rowData.company.name,
                rowData.company.id,
                this.appState.getBaseUrl(),
              )}
            </>
          )
          : (
            <>
            </>
          )}
        {/*
        <br />
        {this.otf.formatOfferRating(rowData.offer_rating)}
        */}
        <br />
        <br />
        <Button label="Details" icon="pi pi-external-link" onClick={() => this.onClick(rowData.id)} />
        <br />
        <br />
        <Dialog
          style={dialogStyle}
          header={dialogHeader}
          visible={dialogVisible}
          footer={dialogFooter}
          breakpoints={dialogBreakPoints}
          onHideDialog={dialogHideFunction}
          onHide={dialogHideFunction}
          closable={false}
        >
          <>
            <Accordion
              multiple
              activeIndex={[0, 5]}
            >
              <AccordionTab header="Specs">
                <>
                  <strong><a href={shortcodeLink} target="_top">{`${rowData.name}`}</a></strong>
                  <br />
                  <br />
                  {/*
                  {this.otf.formatOfferRating(rowData.offer_rating)}
                  <br />
                  */}
                  <strong>Company</strong>
                  {': '}
                  {/*
                  {(rowData.company) ? `${rowData.company.name}` : ''}
                  */}
                  {(rowData.company)
                    ? (
                      <>
                        {this.otf.formatCompanyName(
                          rowData.company.name,
                          rowData.company.id,
                          this.appState.getBaseUrl(),
                        )}
                      </>
                    )
                    : (
                      <>
                      </>
                    )}

                  <br />
                  <strong>CPU</strong>
                  {': '}
                  {`${rowData.cpu_cores_amount} ${rowData.cpu_cores_type} Core(s) ${rowData.cpu_type} ${this.otf.formatCpuSpeed(rowData.cpu_speed)}`}
                  <br />
                  <strong>RAM</strong>
                  {': '}
                  {`${this.otf.formatRam(rowData.ram_amount)} ${this.otf.formatRamType(rowData.ram_type)}`}
                  <br />
                  <strong>Total Storage</strong>
                  {': '}
                  {`${this.otf.formatTotalStorageCapacity(rowData.total_storage_capacity)} ${this.otf.formatStorageType(rowData.storage_type)}`}
                  <br />
                  <br />
                  <Button
                    label="Details"
                    icon="pi pi-search"
                    href={shortcodeLink}
                    rel="noreferrer"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        if (shortcodeLink !== undefined && shortcodeLink !== null && shortcodeLink !== '') {
                          window.open(shortcodeLink, '_top').focus();
                        }
                      }
                    }
                  />
                  <br />
                </>
              </AccordionTab>
              <AccordionTab header="Description">
                <>
                  <strong>Service Type</strong>
                  {': '}
                  {`${this.otf.formatServiceType(rowData.service_type)}`}
                  <br />
                  <br />
                  <strong>Management Type</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.management_type)}`}
                  <br />
                  <br />
                  <strong>Notes</strong>
                  {': '}
                  {`${rowData.notes}`}
                  <br />
                  <br />
                  <strong>Last Modified</strong>
                  {': '}
                  {`${this.otf.formatDate(rowData.last_modified)}`}
                  <br />
                </>
              </AccordionTab>
              <AccordionTab header="Network">
                <>
                  {/*
                  <strong>Bandwidth</strong>
                  {': '}
                  {`${this.otf.formatMonthlyBandwidth(rowData.monthly_bandwidth)} /m`}
                  <br />
                  */}
                  {(parseFloat(rowData.monthly_bandwidth, 10) > 0)
                    ? (
                      <>
                        <strong>Bandwidth: </strong>
                        {`${this.otf.formatMonthlyBandwidth(rowData.monthly_bandwidth)} /m`}
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  {(rowData.unmetered_traffic_inbound)
                    ? (
                      <>
                        <strong>Unmetered Traffic Inbound: </strong>
                        {`${this.otf.formatBoolean(rowData.unmetered_traffic_inbound)}`}
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  {(rowData.unmetered_traffic_outbound)
                    ? (
                      <>
                        <strong>Unmetered Traffic Outbound: </strong>
                        {`${this.otf.formatBoolean(rowData.unmetered_traffic_outbound)}`}
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  <strong>External Port Speed</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.external_network_port_speed)}`}
                  <br />
                  <strong>IPv4</strong>
                  {': '}
                  {`${rowData.ipv4_addresses} ${(rowData.additional_ip4_available) ? 'Additional IPv4 Addresses are Available' : ''}`}
                  {/*
                  <br />
                  <strong>IPv6</strong>
                  {': '}
                  {`${rowData.ipv6_addresses}`}
                  */}
                  {(rowData.ipv6_addresses > 0)
                    ? (
                      <>
                        <strong>IPv6: </strong>
                        {`${rowData.ipv6_addresses}`}
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  <br />
                  <strong>Distributed Denial of Service (DDOS) Protection</strong>
                  {': '}
                  {`${this.otf.formatBoolean(rowData.ddos_protection)}`}
                  <br />
                  <br />
                  <Button
                    label="Looking Glass"
                    icon="pi pi-search"
                    href={lookingGlassLink}
                    rel="noreferrer"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        if (lookingGlassLink !== undefined && lookingGlassLink !== null && lookingGlassLink !== '') {
                          window.open(lookingGlassLink, '_blank').focus();
                        }
                      }
                    }
                  />
                  <br />
                </>
              </AccordionTab>
              <AccordionTab header="Location">
                <>
                  <strong>Location</strong>
                  {': '}
                  {`${rowData.location_iso_3166_alpha_2_country_code} ${rowData.location}`}
                  <br />
                  <br />
                  <>
                    {(googleMapsReady)
                      ? (
                        <>
                          <GMap
                            options={mapOptions}
                            overlays={mapOverlays}
                            style={{ width: '100%', minHeight: '30vh' }}
                          />
                        </>
                      ) : (
                        <>
                        </>
                      )}
                  </>
                </>
              </AccordionTab>
              <AccordionTab header="Features">
                <>
                  <strong>Initial Offer Date</strong>
                  {': '}
                  {`${this.otf.formatDate(rowData.initial_offer_date)}`}
                  <br />
                  <strong>Expiration Date</strong>
                  {': '}
                  {`${this.otf.formatDate(rowData.expiration_date)}`}
                  <br />
                  <strong>Payment Methods</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.payment_methods)}`}
                  <br />
                  <strong>Setup Time</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.setup_time)}`}
                  <br />
                  <strong>Support Methods</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.support_method)}`}
                  <br />
                  <strong>Support Response Time</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.support_response_time)}`}
                  <br />
                  <strong>Control Panel Type</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.control_panel_type)}`}
                  <br />
                  <strong>Windows</strong>
                  {': '}
                  {`${this.otf.formatBoolean(rowData.windows)}`}
                  <br />
                  <strong>Custom ISO</strong>
                  {': '}
                  {`${this.otf.formatBoolean(rowData.custom_iso)}`}
                  <br />
                  <strong>Control Via API</strong>
                  {': '}
                  {`${this.otf.formatBoolean(rowData.control_via_api)}`}
                  <br />
                  <strong>Root Access</strong>
                  {': '}
                  {`${this.otf.formatBoolean(rowData.root_access)}`}
                  <br />
                  <strong>Softaculous</strong>
                  {': '}
                  {`${this.otf.formatBoolean(rowData.softaculous)}`}
                  <br />
                  <strong>Acceptable Use Policy</strong>
                  {': '}
                  {`${this.otf.formatFieldName(rowData.aup)}`}
                  <br />
                </>
              </AccordionTab>
              <AccordionTab header="Pricing">
                <>
                  {/*
                  <strong>First Year Cost</strong>
                  {': '}
                  {`${this.otf.formatCost(rowData.yearly_cost)} /y`}
                  */}
                  {(rowData.yearly_cost > 0)
                    ? (
                      <>
                        <strong>Yearly Cost: </strong>
                        {`${this.otf.formatCost(rowData.yearly_cost)}`}
                        /y
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  <br />
                  {(rowData.monthly_cost > 0)
                    ? (
                      <>
                        <strong>Monthly Cost: </strong>
                        {`${this.otf.formatCost(rowData.monthly_cost)}`}
                        /m
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  <br />
                  {(rowData.second_year_cost > 0)
                    ? (
                      <>
                        <strong>Second Year Cost: </strong>
                        {`${this.otf.formatCost(rowData.second_year_cost)}`}
                        /y
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  {(rowData.setup_fee > 0)
                    ? (
                      <>
                        <strong>Setup Fee: </strong>
                        {`${this.otf.formatCost(rowData.setup_fee)}`}
                        /y
                        <br />
                      </>
                    )
                    : (
                      <>
                      </>
                    )}
                  <br />
                  <Button
                    label="Buy"
                    icon="pi pi-shopping-cart"
                    href={orderLink}
                    rel="noreferrer"
                    title="order"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        if (orderLink !== undefined && orderLink !== null && orderLink !== '') {
                          window.open(orderLink, '_blank').focus();
                        }
                      }
                    }
                  />
                </>
              </AccordionTab>
            </Accordion>
          </>
        </Dialog>
      </>
    );
  }

  column2BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`${rowData.location_iso_3166_alpha_2_country_code} ${rowData.location}`}
        <br />

        {/*
        {`${this.otf.formatMonthlyBandwidth(rowData.monthly_bandwidth)} /m`}
        <br />
        */}
        {(parseFloat(rowData.monthly_bandwidth, 10) > 0)
          ? (
            <>
              <strong>Bandwidth: </strong>
              {`${this.otf.formatMonthlyBandwidth(rowData.monthly_bandwidth)} /m`}
              <br />
            </>
          )
          : (
            <>
            </>
          )}
        {(rowData.unmetered_traffic_inbound)
          ? (
            <>
              <strong>Unmetered Traffic Inbound: </strong>
              {`${this.otf.formatBoolean(rowData.unmetered_traffic_inbound)}`}
              <br />
            </>
          )
          : (
            <>
            </>
          )}
        {(rowData.unmetered_traffic_outbound)
          ? (
            <>
              <strong>Unmetered Traffic Outbound: </strong>
              {`${this.otf.formatBoolean(rowData.unmetered_traffic_outbound)}`}
              <br />
            </>
          )
          : (
            <>
            </>
          )}
        <strong>IPv4</strong>
        {': '}
        {`${rowData.ipv4_addresses} ${(rowData.additional_ip4_available) ? 'Additional IPv4 Addresses are Available' : ''}`}
        <br />
        {/*
        <strong>IPv6</strong>
        {': '}
        {`${rowData.ipv6_addresses}`}
        <br />
        */}
        {(rowData.ipv6_addresses)
          ? (
            <>
              <strong>IPv6: </strong>
              {`${rowData.ipv6_addresses}`}
            </>
          )
          : (
            <>
            </>
          )}
      </>
    );
  }

  column3BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`${this.otf.formatRam(rowData.ram_amount)} ${this.otf.formatRamType(rowData.ram_type)}`}
        <br />
      </>
    );
  }

  column4BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`${this.otf.formatTotalStorageCapacity(rowData.total_storage_capacity)} ${this.otf.formatStorageType(rowData.storage_type)}`}
        <br />
      </>
    );
  }

  column5BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    const secondYearCost = (rowData.second_year_cost > 0) ? `Second Year: ${this.otf.formatCost(rowData.second_year_cost)} <br />` : '';

    let orderLink = (rowData.order_link) ? rowData.order_link : '#';
    orderLink = (orderLink.indexOf('://') === -1) ? `https://${orderLink}` : orderLink;

    return (
      <>
        {/*
        {`${this.otf.formatCost(rowData.yearly_cost)} /y`}
        */}
        {(rowData.yearly_cost > 0)
          ? (
            <>
              <strong>Yearly Cost: </strong>
              {`${this.otf.formatCost(rowData.yearly_cost)}`}
              /y
              <br />
            </>
          )
          : (
            <>
            </>
          )}
        <br />
        {(rowData.monthly_cost > 0)
          ? (
            <>
              <strong>Monthly Cost: </strong>
              {`${this.otf.formatCost(rowData.monthly_cost)}`}
              /m
              <br />
            </>
          )
          : (
            <>
            </>
          )}
        {`${secondYearCost}`}
        <br />
        <Button
          label=""
          icon="pi pi-shopping-cart"
          href={orderLink}
          rel="noreferrer"
          onClick={
            (e) => {
              e.preventDefault();
              window.open(orderLink, '_blank').focus();
            }
          }

        />
        <br />
      </>
    );
  }

  renderFooter(id) {
    return (
      <div>
        <Button label="close" icon="pi pi-check" onClick={() => this.onHideDialog(id)} autoFocus />
      </div>
    );
  }

  render() {
    const {
      offerRows,
      offerColumns,
    } = this.state;

    const dynamicColumns = offerColumns.map( // eslint-disable-line no-unused-vars
      // filter
      (col) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable
          reorderable
        />
      ),
    );

    const footer = `In total there are ${offerRows ? offerRows.length : 0} offers.`;

    return (
      <>
        { (Array.isArray(offerColumns) && Array.isArray(offerRows))
          ? (
            <div>
              <AppOfferFilters parent={this} />
              {/* p-datatable-sm */}
              {/* rowsPerPageOptions={[20, 50]} */}
              <DataTable
                value={offerRows}
                paginator
                resizableColumns
                columnResizeMode="fit"
                className="p-datatable-responsive p-datatable-sm"
                rows={this.settings.getDefaultRowsPerPageOptions()}
                rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
                footer={footer}
              >
                <Column
                  key="name"
                  field="name"
                  header="Name"
                  body={this.column1BodyTemplate}
                  style={{ width: '31vw' }}
                  filter
                  sortable
                  reorderable
                />
                <Column
                  key="location_iso_3166_alpha_2_country_code"
                  field="location_iso_3166_alpha_2_country_code"
                  header="Location"
                  body={this.column2BodyTemplate}
                  style={{ width: '16vw' }}
                  filter
                  sortable
                  reorderable
                />
                <Column
                  key="ram_amount"
                  field="ram_amount"
                  header="RAM"
                  body={this.column3BodyTemplate}
                  style={{ width: '12vw' }}
                  filter
                  sortable
                  reorderable
                />
                <Column
                  key="total_storage_capacity"
                  field="total_storage_capacity"
                  header="Storage"
                  body={this.column4BodyTemplate}
                  style={{ width: '16vw' }}
                  filter
                  sortable
                  reorderable
                />
                <Column
                  key="monthly_cost"
                  field="monthly_cost"
                  header="Cost"
                  body={this.column5BodyTemplate}
                  style={{ width: '19vw' }}
                  filter
                  sortable
                  reorderable
                />
              </DataTable>
            </div>
          )
          : <></> }
        <br />
        {/* */}
        <Button
          label="Load More"
          icon="pi pi-refresh"
          onClick={() => {
            this.loadMoreOffers();
          }}
        />
        <Toast
          position="top-right"
          ref={(el) => {
            this.toast = el;
          }}
        />
      </>
    );
  }
}

AppOffers.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppOffers.defaultProps = {
  parent: null,
};

export default AppOffers;
