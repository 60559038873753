import Settings from '../settings/settings';

class Console {
  constructor() {
    this.orignalConsole = console;
    this.settings = new Settings();

    this.messageArray = [];

    this.overrideConsole();
  }

  overrideConsole() { // eslint-disable-line class-methods-use-this
    const orignalConsole = console;

    // const { messageArray } = this.messageArray;

    const newConsole = {
      // ...console,
      log(message) { // eslint-disable-line no-unused-vars
        orignalConsole.log(message);
        // messageArray.push(message);
      },
      info(message) {
        orignalConsole.info(message);
      },
      warn(message) {
        orignalConsole.warn(message);
      },
      error(message) {
        orignalConsole.error(message);
      },
    };
    window.console = newConsole;
  }
}

export default Console;
