import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toXML } from 'jstoxml';
import Settings from '../settings/settings';
import Query from '../query/query';

const moment = require('moment');

class Sitemap extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query(this.appState);

    this.state = {
      offerRows: [], // eslint-disable-line react/no-unused-state
      companyRows: [], // eslint-disable-line react/no-unused-state
    };

    this.loadOffers = this.loadOffers.bind(this);
    this.loadCompanies = this.loadCompanies.bind(this);

    this.generateSitemap = this.generateSitemap.bind(this);
  }

  componentDidMount() {
    this.loadCompanies();
    this.loadOffers();
    this.generateSitemap();
  }

  loadCompanies() {
    const {
      endPoint,
      body,
    } = this.query.getCompanyPageEndPoint();

    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              const companyRows = json.data.companies;

              this.setState({
                companyRows, // eslint-disable-line react/no-unused-state
              });
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  loadOffers() {
    const offset = 0;
    const {
      endPoint,
      body,
    } = this.query.getOfferFilterQuery(
      [0, this.settings.getYearlyCostMaxValue()],
      [0, this.settings.getRamAmountMaxValue()],
      [0, this.settings.getTotalStorageCapacityMaxValue()],
      offset,
      this.settings.getMaxOfferLimit(),
      this.appState.getToken(),
    );

    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.data) {
                const offerJson = json.data.offersFilter;
                if (offerJson) {
                  if (offerJson.length > 0) {
                    const offerRows = json.data.offersFilter;

                    this.setState({
                      offerRows, // eslint-disable-line react/no-unused-state
                    });
                  } else {
                    const offerRows = [];
                    this.setState({
                      offerRows, // eslint-disable-line react/no-unused-state
                    });
                  }
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  generateSitemap() { // eslint-disable-line class-methods-use-this
    const {
      offerRows,
      companyRows,
    } = this.state;

    const providers = [];
    companyRows.forEach((company) => {
      const url = {
        url: {
          loc: `${Settings.BASE_URL()}/p/${company.id}`,
          // lastmod: () => new Date(),
          lastmod: moment().format('YYYY-MM-DD'),
          changefreq: 'monthly',
          priority: 0.8,
        },
      };
      providers.push(url);
    });

    const offers = [];
    offerRows.forEach((row) => {
      const url = {
        url: {
          loc: `${Settings.BASE_URL()}/o/${row.shortcode}`,
          // lastmod: () => new Date(),
          lastmod: moment().format('YYYY-MM-DD'),
          changefreq: 'monthly',
          priority: 0.8,
        },
      };
      offers.push(url);
    });

    const xmlOptions = {
      header: true,
      indent: '  ',
    };

    const xml = toXML(
      {
        _name: 'urlset',
        _attrs: {
          xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9',
        },
        _content: [
          providers,
          offers,
        ],
      },
      xmlOptions,
    );
    return xml;
  }

  render() {
    return (
      <>
        <h1>Sitemap</h1>
        <div className="sitemap">
          <pre>
            {this.generateSitemap()}
          </pre>
        </div>
      </>
    );
  }
}

Sitemap.propTypes = {
  parent: PropTypes.object,
};

Sitemap.defaultProps = {
  parent: null,
};

export default Sitemap;
