import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pages from '../assets/pages.json'; // eslint-disable-line no-unused-vars
import Settings from '../settings/settings';

function capitalize(input) {
  return input.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

function pathToTitle(pathname) {
  let returnValue = `${pathname}`;
  returnValue = returnValue.replace('-', ' ').replace('/', '');
  returnValue = capitalize(returnValue);
  return returnValue;
}

class Page extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;
    this.settings = new Settings();
  }

  componentDidMount() {
  }

  render() {
    const { location } = window;
    let content = [];
    let title = pathToTitle(location.pathname);
    const locationPathname = location.pathname.replace('/staging', '');

    if (Array.isArray(Pages.pages)) {
      for (let i = 0; i < Pages.pages.length; i += 1) {
        if (Pages.pages[i].path === locationPathname) {
          if (Pages.pages[i].content) {
            content.push(Pages.pages[i].content);
          } else {
            content = (
              <iframe className="pageFrame" src={`${this.settings.getBaseUrl()}${Pages.pages[i].url}`} title={locationPathname} />
            );
          }
        }
      }
    }

    if (content.length === 0) {
      title = pathToTitle(location.pathname);
      content.push((
        '404'
      ));
    }
    console.log(`content.length: ${content.length}`);
    return (
      <div>
        <h1>
          {`${title}`}
        </h1>
        <br />
        {content}
        <br />
      </div>
    );
  }
}

Page.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

Page.defaultProps = {
  parent: null,
};

export default Page;
